import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
// selectors
import { selectIsCreatedUser } from "store/reservationRequests/reservationRequestsSelectors";
// actions
import { reservationRequestsActions } from "store/reservationRequests/reservationRequestsSlice";
// mui
import { Box, Button, Typography } from "@mui/material";

const ReservationRequestSubmitted:React.FC = () => {
  const isCreatedUser = useAppSelector(selectIsCreatedUser);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(reservationRequestsActions.setIsCreatedUser(false));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ height: 'calc(100vh - 236px)' ,display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f5f6f7' }}>
      <img src="/img/icons/Done.svg" alt="done" style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
      <Typography sx={{ pt: 3 }}>Your request has been sent successfully</Typography>
      {isCreatedUser ? (
        <Typography sx={{ pt: 0.5 }}>and new account has been created</Typography>
      ) : (
        <Typography sx={{ pt: 0.5 }} variant="body2" color="text.secondary" >We’ll get back to you soon with a price offer</Typography>
      )}
      <Box sx={{ mt: 6, display: 'flex', gap: 1 }}>
        {!isCreatedUser && (
          <Button
            variant="outlined"
            component={NavLink}
            to="/reservation-requests"
          >
            View reservation request
          </Button>
        )}
        <Button
          variant="contained"
          component={NavLink}
          to="/"
        >
          Back to homepage
        </Button>
      </Box>
    </Box>
  )
}

export default ReservationRequestSubmitted;
