import { createAsyncThunk } from "@reduxjs/toolkit";
// Types
import ImageAssetType from "types/ImageAssetType";
// Utilities
import HttpClient from 'utilities/HttpClient';
import { AssetsActions } from "./assetsSlice";

export default class AssetsAsync {
  private static _url:string = '/client/assets';
  private static _http:HttpClient = new HttpClient();

  // Create assets
  public static createAssets = createAsyncThunk(
  'assets/createAssets',
  async (assetData:{
    file: File,
    imageAssetType?: ImageAssetType
  }, thunkApi) => {

    const { file, imageAssetType = ImageAssetType.Small } = assetData;

    const formData = new FormData();
    formData.append('file', file, file.name);

    const queryParams = new URLSearchParams();
    queryParams.append('imageAssetType', imageAssetType);

    const response:Response = await this._http.post(`${this._url}?${queryParams}`, formData);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    thunkApi.dispatch(AssetsActions.setInitialField('file'));
    return response.json();
  });
}
