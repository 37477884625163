import React from 'react';
// Types
import DialogReasons from 'types/DialogReasons';
// Mui
import { Dialog as MuiDialog, DialogProps } from '@mui/material';

const useDialog = () => {
  const [ open, setOpen ] = React.useState<boolean>(false);

  const openDialog = React.useCallback(() => {
    setOpen(true);
    // eslint-disable-nextline
  }, []);

  const closeDialog = React.useCallback(() => {
    setOpen(false);
    // eslint-disable-nextline
  }, []);

  const Dialog = React.useMemo(() => {
    const DialogComponent:React.FC<Omit<DialogProps, 'open' | 'onClose'>>  = ({ children, ...props }) => {
      const handleClose = (_:any, reason:DialogReasons) => {
        // This is for disable backdrop click
        // Prev projects we got problem that users missclicked on backdrop and accidentally close dialog
        if ( reason === DialogReasons.BackdropClick ) return;
        closeDialog();
      };
      return (
        <MuiDialog
          {...props}
          open={open}
          onClose={handleClose}
          fullWidth
        >{children}</MuiDialog>
      )
    }
    return DialogComponent;
    // eslint-disable-next-line
  }, [open]);

  return {
    Dialog,
    openDialog,
    closeDialog
  }
}


export default useDialog;