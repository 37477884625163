import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import useDialog from "hooks/useDialog";
// Async
import PropertiesAsync from "store/properties/propertiesAsync";
import ReservationRequestsAsync from "store/reservationRequests/reservationRequestsAsync";
// Actions
import { reservationRequestsActions } from "store/reservationRequests/reservationRequestsSlice";
import { PropertiesActions } from "store/properties/propertiesSlice";
// Models
import IProperty from "models/Property";
// Types
import UserRoles from "types/UserRoles";
// Selectors
import { selectIsAuthenticated } from "store/auth/authSelectors";
import { selectAllProperties } from "store/properties/propertiesSelectors";
import { selectParams, selectTimeshareDetails } from "store/timeshares/timesharesSelectors";
import { selectCurrentUser } from "store/users/usersSelectors";
// components
import SignInForm from "./SignIn.form";
import Title from "./Title";
import { Input } from "./Controls";
import Phone, { getCallingCode, getPhoneWithoutCallingCode } from "./Phone";
import AddGuestCapabilities from "./AddGuestCapabilities";
// mui
import { LoadingButton } from "@mui/lab";
import { DesktopDatePicker } from '@mui/x-date-pickers';
import {
  Autocomplete, Box, Button, Checkbox,
  debounce, Grid, Paper, TextField, Typography
} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// Utilities
import { isEmailValid, isFieldRequired, isValidDate } from "utilities/Validation";
import { getContent } from 'utilities/Utilities';

type Props = {
  onClose?: () => void;
  clearDates?: boolean;
}

interface IForm {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  callingCode?: string;
  role?: UserRoles;
  notificationsEnabled?: boolean;

  propertyId?: any;
  start: any;
  end: any;
  guestNotes: string;
};

const ReservationRequestForm:React.FC<Props> = ({ onClose, clearDates = false }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const currentUser = useAppSelector(selectCurrentUser);
  const timeshare = useAppSelector(selectTimeshareDetails);
  const timesharesParams = useAppSelector(selectParams);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const properties = useAppSelector(selectAllProperties) || [];

  const [accepted, setAccepted] = useState<boolean>(isAuthenticated ? true : false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingProperties, setIsLoadingProperties] = useState<boolean>(false);

  useEffect(() => { if(isAuthenticated) setAccepted(true)}, [isAuthenticated]);

  const startDate = timesharesParams?.start || timeshare?.start;
  const endDate = timesharesParams?.end || timeshare?.end;

  const { control, handleSubmit, formState:{ errors }, setValue, watch } = useForm<IForm>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      callingCode: '',
      phone: '',
      role: UserRoles.Guest,
      notificationsEnabled: true,

      propertyId: timeshare?.property || timesharesParams.propertyId || null,
      start: !clearDates ? dayjs(startDate) : null,
      end: !clearDates ? endDate ? dayjs(endDate) : dayjs().add(7, 'days') : null,
      guestNotes: '',
    }
  });

  const startDateWatcher = watch('start');
  const endDateWatcher = watch('end');

  const onSubmit = handleSubmit((data: IForm) => {
    if(currentUser && !currentUser?.roles.includes(UserRoles.Guest)) {
      openGuestCapabilitiesDialog();
      return;
    }
  
    setIsLoading(true);
    const { firstName, lastName, email, phone, callingCode, role, notificationsEnabled, propertyId, ...reservationRequestData } = data;

    if (isAuthenticated) {
      const nextData: IForm = {
        ...reservationRequestData,
        start: dayjs(reservationRequestData.start).format('YYYY-MM-DD'),
        end: dayjs(reservationRequestData.end).format('YYYY-MM-DD'),
      }
      if (propertyId) nextData['propertyId'] = propertyId.id;

      dispatch(ReservationRequestsAsync.createReservationRequest(nextData))
        .unwrap()
        .then(() => {
          if (onClose) onClose();
          navigate('/submitted-request');
        })
        .finally(() => {
          setIsLoading(false);
        })
    } else {
      const nextData: any = {
        registrationRequestCreateData: {
          accountId: getContent('settings').accountId,
          firstName, lastName, email, role, notificationsEnabled,
          callingCode: callingCode ? getCallingCode(callingCode) : null,
          phone: phone && callingCode ? getPhoneWithoutCallingCode(callingCode, phone) : null,
        },
        reservationRequestCreateData: {
          ...reservationRequestData,
          start: dayjs(reservationRequestData.start).format('YYYY-MM-DD'),
          end: dayjs(reservationRequestData.end).format('YYYY-MM-DD'),
        }
      }

      if (propertyId) nextData.reservationRequestCreateData['propertyId'] = propertyId.id;

      dispatch(ReservationRequestsAsync.createReservationRequestAnonymous(nextData))
        .unwrap()
        .then(() => {
          dispatch(reservationRequestsActions.setIsCreatedUser(true));
        })
        .then(() => {
          if (onClose) onClose();
          navigate('/submitted-request');
        })
        .finally(() => {
          setIsLoading(false);
        })
    }
  })

  // eslint-disable-next-line
  const debounceProperty = useCallback(debounce((search:string) => {
    if (search.trim()) {
      setIsLoadingProperties(true);
      dispatch(PropertiesAsync.fetchProperties({ search, size: 50 }))
        .unwrap()
        .finally(() => setIsLoadingProperties(false));
    } else {
      dispatch(PropertiesActions.clearProperties());
    }
  }, 500), []);

  const onChangeProperty = (event:React.ChangeEvent<HTMLInputElement>) => {
    debounceProperty(event.target.value);
  }

  const { Dialog, openDialog, closeDialog } = useDialog();
  const { Dialog: GuestCapabilitiesDialog, openDialog: openGuestCapabilitiesDialog, closeDialog: closeGuestCapabilitiesDialog } = useDialog();

  return (
    <React.Fragment>
      <Dialog maxWidth="sm">
        <SignInForm onClose={closeDialog} requestQuote={true} />
      </Dialog>
      <GuestCapabilitiesDialog maxWidth="sm">
        <AddGuestCapabilities onClose={closeGuestCapabilitiesDialog} />
      </GuestCapabilitiesDialog>

      <Box>
        {pathname !== '/timeshares' && !onClose && (
          <Box sx={{ pb: 2 }}>
            <Title>Can't find what you are looking for?</Title>
          </Box>
        )}
        <Paper sx={{ boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.08)', borderRadius: '8px', p: { xs: 2, sm: 3 } }}>
          {pathname === '/timeshares' && !onClose && (
            <Box sx={{ pb: 2 }}>
              <Title>Can't find what you are looking for?</Title>
            </Box>
          )}
          <form onSubmit={onSubmit} noValidate>
            {!isAuthenticated && (
              <React.Fragment>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, backgroundColor: `rgba(${getContent('theme').primaryColorRgb}, 0.12)`, p: 2, flexDirection: { xs: 'column', md: 'row' }  }}>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <InfoOutlinedIcon sx={{ color: getContent('theme').primaryColor }} />
                    <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>By sending a reservation request, a new account will be created</Typography>
                  </Box>
                  <Button
                    size="small"
                    onClick={openDialog}
                  >
                    Already registered? Sign in
                  </Button>
                </Box>
                <Box sx={{ pt: 3 }}>
                  <Typography>Contact details</Typography>
                  <Grid container spacing={2}>
                    {/* First Name */}
                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control} name="firstName"
                        rules={{ required: isFieldRequired }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            label="First name"
                            required
                            error={Boolean(errors.firstName)}
                            helperText={errors.firstName ? errors.firstName.message : ''}
                          />
                        )}
                      />
                    </Grid>
                    {/* Last Name */}
                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control} name="lastName"
                        rules={{ required: isFieldRequired }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            label="Last name"
                            required
                            error={Boolean(errors.lastName)}
                            helperText={errors.lastName ? errors.lastName.message : ''}
                          />
                        )}
                      />
                    </Grid>

                    {/* E-mail */}
                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control} name="email"
                        rules={{ required: isFieldRequired, pattern: isEmailValid }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            label="E-mail" type="email"
                            required
                            error={Boolean(errors.email)}
                            helperText={errors.email ? errors.email.message : ''}
                          />
                        )}
                      />
                    </Grid>

                    {/* Phone */}
                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control} name="phone"
                        render={({ field:{ value, onChange } }) => (
                          <Phone
                            label="Cell phone" value={value || ''}
                            onChange={([ callingCode, phone ]:string[]) => {
                              setValue('callingCode', callingCode);
                              onChange(phone);
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </React.Fragment>
            )}
            <Box sx={{ pt: 3 }}>
              <Typography>Request details</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    control={control} name="propertyId"
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        sx={{ backgroundColor: '#fff' }}
                        fullWidth
                        disablePortal
                        id="reservation-request-select-propertyId"
                        options={properties}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(property: IProperty) => property.name}
                        value={value || null}
                        onChange={(_:any, value:IProperty | null) => onChange(value || null)}
                        onClose={() => debounceProperty('')}
                        loading={isLoadingProperties}
                        loadingText="Search..."
                        noOptionsText=""
                        forcePopupIcon={false}
                        filterOptions={options => options}
                        renderOption={(props, option) => (
                          <li {...props} key={option.id} style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            {option.name}
                          </li>
                        )}
                        renderInput={(params) => 
                          <TextField
                            {...params}
                            margin="normal"
                            label="Property"
                            onChange={onChangeProperty}
                            placeholder="e.g. Marriott"
                            helperText="Don’t see what you’re looking for? Submit your desired destination in the notes and an agent will contact you."
                          />
                        }
                      />
                    )}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      {/* Start date */}
                      <Controller
                        control={control} name="start"
                        rules={{ required: isFieldRequired, validate: {
                          isValid: (date: Dayjs | null) => isValidDate(date),
                        } }}
                        render={({ field: { onChange, value } }) => (
                          <DesktopDatePicker
                            disablePast
                            value={value ? dayjs(value) : null}
                            onChange={(date:Dayjs | null) => {
                              const today = dayjs();
                              const nextDate = date?.isBefore(today) ? today : date;
                              onChange(nextDate);
                              if (nextDate && (!endDateWatcher || dayjs(nextDate).isAfter(dayjs(endDateWatcher).subtract(1, 'day')))) {
                                setValue('end', dayjs(nextDate).add(7, 'day'));
                              }
                            }}
                            label="Start date"
                            views={['year', 'month', 'day']}
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                margin: 'normal',
                                required: true,
                                error: !!errors?.start,
                                helperText: errors?.start?.message || ''
                              },
                              actionBar: {
                                actions: ['accept'],
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      {/* End date */}
                      <Controller
                        control={control} name="end"
                        rules={{ required: isFieldRequired, validate: {
                          isValid: (date: Dayjs | null) => isValidDate(date),
                        } }}
                        render={({ field: { onChange, value } }) => (
                          <DesktopDatePicker
                            minDate={startDateWatcher ? dayjs(startDateWatcher).add(1, 'day') : dayjs().add(1, 'day')}
                            value={value ? dayjs(value) : null}
                            onChange={(date:Dayjs | null) => {
                              const today = dayjs();
                              const nextDate = startDateWatcher && date?.isBefore(startDateWatcher.add(1, 'day'))
                                ? dayjs(startDateWatcher).add(1, 'day')
                                : date?.isBefore(today.add(1, 'day'))
                                  ? today.add(1, 'day')
                                  : date;
                              onChange(nextDate);
                            }}
                            label="End date"
                            views={['year', 'month', 'day']}
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                margin: 'normal',
                                required: true,
                                error: !!errors?.end,
                                helperText: errors?.end?.message || ''
                              },
                              actionBar: {
                                actions: ['accept'],
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    {/* guestNotes */}
                    <Controller
                      control={control} name="guestNotes"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          margin="normal"
                          label="Notes"
                          type="text"
                          multiline
                          rows={3}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            {!isAuthenticated && (
              <React.Fragment>
                <label style={{ display: 'flex', alignItems: 'center', paddingTop: '8px' }}>
                  <Checkbox checked={accepted} onChange={(_:any, checked: boolean) => setAccepted(checked)} />
                  <Typography sx={{ fontSize: '14px' }}>
                    {'* I have read and accepted the '}
                    <NavLink to="/terms-and-conditions" target="_blank" style={{ color: getContent('theme').primaryColor, textDecoration: 'none' }}>Terms & Conditions</NavLink>
                    {' and '}
                    <NavLink to="/privacy-policy" target="_blank" style={{ color: getContent('theme').primaryColor, textDecoration: 'none' }}>Privacy Policy</NavLink>
                  </Typography>
                </label>
              </React.Fragment>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 2, gap: 2 }}>
              {onClose && (
                <Button
                  variant="outlined"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              )}
              <LoadingButton
                loading={isLoading}
                variant="contained"
                disabled={!accepted}
                type="submit"
              >
                Send reservation request
              </LoadingButton>
            </Box>
          </form>
        </Paper>
      </Box>
    </React.Fragment>
  )
}

export default ReservationRequestForm;
