import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Models
import IProperty from "models/Property";
import ITimeshare from "models/Timeshare";
import { ITimesharesGroup } from "models/TimesharesGroup";
// Types
import TimeshareStatus from "types/TimeshareStatus";
// Async
import TimesharesAsync from "./timesharesAsync";

interface ITimeshareSimpleData extends Pick<ITimeshare, 'id' | 'start' | 'end'> {};

interface IState {
  timeshares: ITimeshare[] | null;
  timesharesByProperty: ITimeshareSimpleData[] | null;
  total: number;
  myTimesharesTotal: number;
  myTimeshares: ITimeshare[] | null;
  timesharesGroups: ITimesharesGroup | null;
  timeshareDetails: ITimeshare | null;
  formData: {
    property: IProperty | null,
  };
  params: any;
  myTimesharesParams: {
    statuses: TimeshareStatus[],
  };
}

const initialState:IState = {
  timeshares: null,
  timesharesByProperty: null,
  total: 0,
  myTimesharesTotal: 0,
  myTimeshares: null,
  timesharesGroups: null,
  timeshareDetails: null,
  formData: {
    property: null,
  },
  params: {},
  myTimesharesParams: {
    statuses: [TimeshareStatus.Available],
  },
};

const timesharesSlice = createSlice({
  name: 'timeshares',
  initialState,
  reducers: {
    setTimeshareDetails: (state, action:PayloadAction<ITimeshare | null>) => {
      state.timeshareDetails = action.payload;
    },
    setFormData: (state, action:PayloadAction<any>) => {
      state.formData.property = action.payload;
    },
    setParams: (state, action:PayloadAction<any>) => {
      state.params = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch property timeshares (search)
      .addCase(TimesharesAsync.fetchTimeshares.pending, (state, action) => {
        state.timeshares = null;
      })
      .addCase(TimesharesAsync.fetchTimeshares.fulfilled, (state, action:PayloadAction<any>) => {
        state.timeshares = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(TimesharesAsync.fetchTimesharesByProperty.pending, (state, action) => {
        state.timesharesByProperty = null;
      })
      .addCase(TimesharesAsync.fetchTimesharesByProperty.fulfilled, (state, action:PayloadAction<ITimeshareSimpleData[]>) => {
        state.timesharesByProperty = action.payload;
      })
      // Fetch property timeshare (by id)
      .addCase(TimesharesAsync.fetchTimeshare.pending, (state) => {
        state.timeshareDetails = null;
      })
      .addCase(TimesharesAsync.fetchTimeshare.fulfilled, (state, action:PayloadAction<ITimeshare>) => {
        state.timeshareDetails = action.payload;
      })
      // Fetch my property timeshares
      .addCase(TimesharesAsync.fetchMyTimeshares.pending, (state, action:any) => {
        state.myTimesharesParams = action.meta.arg
        state.myTimeshares = null;
        state.myTimesharesTotal = 0;
      })
      .addCase(TimesharesAsync.fetchMyTimeshares.fulfilled, (state, action:PayloadAction<any>) => {
        state.myTimeshares = action.payload.data;
        state.myTimesharesTotal = action.payload.total;
      })
      // Fetch property timeshares groups
      .addCase(TimesharesAsync.fetchTimesharesGroups.pending, (state) => {
        state.timesharesGroups = null;
      })
      .addCase(TimesharesAsync.fetchTimesharesGroups.fulfilled, (state, action:PayloadAction<any>) => {
        state.timesharesGroups = action.payload;
      })
      // Create property timeshare
      .addCase(TimesharesAsync.createTimeshares.fulfilled, (state, action:PayloadAction<any>) => {
        state.myTimeshares = state.myTimeshares
          ? state.myTimesharesParams.statuses.includes(TimeshareStatus.Available) ? [action.payload, ...state.myTimeshares] : state.myTimeshares
          : state.myTimesharesParams.statuses.includes(TimeshareStatus.Available) ? [action.payload] : [];
        state.myTimesharesTotal = state.myTimesharesTotal + 1;
      })
      // Delete property timeshare
      .addCase(TimesharesAsync.deleteTimeshares.fulfilled, (state, action:any) => {
        const { arg:timeshareId } = action.meta;
        state.myTimeshares = state.myTimeshares
          ? state.myTimeshares.filter((timeshare: ITimeshare) => timeshare.id !== timeshareId)
          : [];
        state.myTimesharesTotal = state.myTimesharesTotal - 1;
      })
      // Update property timeshare
      .addCase(TimesharesAsync.updateTimeshares.fulfilled, (state, action:PayloadAction<any>) => {
        state.myTimeshares = state.myTimeshares
          ? state.myTimeshares.map((timeshare: ITimeshare) => {
            if (timeshare.id === action.payload.id) {
              return action.payload;
            } else {
              return timeshare;
            }
          }) : [];
      })
  }
});

export const TimesharesActions = timesharesSlice.actions;

export default timesharesSlice.reducer;
