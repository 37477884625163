import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
// Types
import UserRoles from 'types/UserRoles';
// Async
import UsersAsync from 'store/users/usersAsync';
// Actions
import { UiActions } from 'store/ui/uiSlice';
// Selectors
import { selectCurrentUser } from 'store/users/usersSelectors';
// Components
import { getCallingCode, getPhoneWithoutCallingCode, setPhoneWithCallingCode } from 'components/Phone';
// MUI
import { Card, Typography, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
// Utilities
import { getContent } from 'utilities/Utilities';

type Props = {
  onClose: () => void,
}

interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
  secondaryEmail: string;
  phone: string;
  callingCode: string
  secondaryPhone: string;
  secondaryCallingCode: string
  notificationPreferences: {
    sms: boolean;
    email: boolean
  },
  roles: UserRoles[],
};

const AddGuestCapabilities:React.FC<Props> = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector(selectCurrentUser);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { handleSubmit } = useForm<IFormData>({
    defaultValues: {
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      email: currentUser?.email,
      secondaryEmail: currentUser?.secondaryEmail || '',
      callingCode: currentUser?.callingCode || '',
      phone: setPhoneWithCallingCode(currentUser?.callingCode || '', currentUser?.phone || ''),
      secondaryCallingCode: currentUser?.secondaryCallingCode || '',
      secondaryPhone: setPhoneWithCallingCode(currentUser?.secondaryCallingCode || '', currentUser?.secondaryPhone || ''),
      notificationPreferences: {
        sms: currentUser?.notificationPreferences.sms,
        email: currentUser?.notificationPreferences.email,
      },
      roles: [...currentUser?.roles || [], UserRoles.Guest],
    }
  });

  const onSubmit = handleSubmit((data:IFormData) => {
    setIsLoading(true);

    const { phone, callingCode, secondaryPhone, secondaryCallingCode, secondaryEmail, ...nextData } = data as any;

    dispatch(UsersAsync.updateUser({
      ...nextData,
      callingCode: getCallingCode(callingCode),
      phone: getPhoneWithoutCallingCode(callingCode, phone),
      secondaryCallingCode: secondaryPhone ? getCallingCode(secondaryCallingCode) : null,
      secondaryPhone: secondaryPhone ? getPhoneWithoutCallingCode(secondaryCallingCode, secondaryPhone) : null,
      secondaryEmail: secondaryEmail ? secondaryEmail : null,
    }))
      .unwrap()
      .then(() => {
        dispatch(UsersAsync.switchRole(UserRoles.Guest));
      })
      .then(() => {
        dispatch(UiActions.enqueueSnackbar({ message: 'Profile was updated' }));
        onClose();
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  return (
    <Card sx={{ padding: '32px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <IconButton onClick={onClose} sx={{ position: 'absolute', top: '16px', right: '16px' }}>
        <CloseOutlinedIcon />
      </IconButton>
      <Typography sx={{ fontSize: '20px', pt: 2 }}>{`To send reservation request enable ${getContent('labels').labelGuestSingularText} capabilities for your account.`}</Typography>
      <LoadingButton
        sx={{ mt: 4 }}
        variant="contained"
        onClick={onSubmit}
        loading={isLoading}
      >
        {`Enable ${getContent('labels').labelGuestSingularText} capabilities`}
      </LoadingButton>
    </Card>
  );
}

export default AddGuestCapabilities;
