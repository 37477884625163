import React, { useEffect, useRef, useState } from "react";
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
// Async
import TimesharesAsync from "store/timeshares/timesharesAsync";
import DestinationTypesAsync from "store/destinationTypes/destinationTypesAsync";
// Actions
import { TimesharesActions } from "store/timeshares/timesharesSlice";
// Selectors
import { selectParams, selectTimeshares, selectTotal } from "store/timeshares/timesharesSelectors";
import { selectAllDestinationTypes } from "store/destinationTypes/destinationTypesSelectors";
// Components
import Title from "components/Title";
import { Loader, NoSearchData } from "components/Utilities";
import TimeshareCard from "components/TimeshareCard";
import SearchBanner from "components/SearchBanner";
import ReservationRequestForm from "components/ReservationRequest.form";
import TimeshareFilters from "./TimeshareFilters";
// MUI
import { Box, Chip, Pagination, Typography, Button, Drawer, IconButton, Grid } from "@mui/material";
import { makeStyles } from '@mui/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
// Utilities
import { getContent, textFromCamelToNormalCase } from 'utilities/Utilities';

const Timeshares:React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const didMountRef = useRef<any>(null);

  const limit = 15;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const destinationTypes = useAppSelector(selectAllDestinationTypes) || [];
  const timeshares = useAppSelector(selectTimeshares);
  const total = useAppSelector(selectTotal);
  const params = useAppSelector(selectParams);

  const onRemoveFilter = (field: string, value?: string) => {
    if (value) {
      const newParams = {
        ...params,
        [field]: params[field].filter((param: string) => param !== value),
      };
      dispatch(TimesharesActions.setParams(newParams));
    } else {
      const newParams = {...params};
      delete newParams[field];
      dispatch(TimesharesActions.setParams(newParams));
    }
  }

  const [ page, setPage ] = useState(1);

  const onChangePage = (value: number) => {
    setPage(value);
  }

  useEffect(() => {
    setIsLoading(true);
    didMountRef.current = null;
    setPage(1);
    dispatch(TimesharesAsync.fetchTimeshares({ ...params, propertyId: params.propertyId ? params.propertyId.id : '', size: limit, page: 0 }))
      .unwrap()
      .then(() => didMountRef.current = true)
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsLoading(true);
    dispatch(TimesharesAsync.fetchTimeshares({ ...params, propertyId: params.propertyId ? params.propertyId.id : '', size: limit, page: page - 1 }))
      .unwrap()
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    dispatch(DestinationTypesAsync.fetchDestinationTypes({}));
    window.scrollTo({ top: 0, behavior: "smooth" });

    return () => {
      dispatch(TimesharesActions.setParams({}));
    }
    // eslint-disable-next-line
  }, []);


  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const handleOpenFilter = () => {
    setOpenFilter(!openFilter);
  }

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={openFilter}
        onClose={handleOpenFilter}
        sx={{ maxWidth: '100%', zIndex: 1300, display: 'block', '@media (min-width: 900px)': { display: 'none' } }}
      >
        <TimeshareFilters onClose={handleOpenFilter} />
        <Box sx={{
          display: 'flex', justifyContent: 'flex-end', gap: '20px', p: 2, zIndex: 1200,
          position: 'sticky', bottom: 0, backgroundColor: '#fff', borderTop: '1px solid #eee'
        }}>
          <Button variant="text" onClick={handleOpenFilter}>
            Clear all
          </Button>
          <Button variant="contained" onClick={handleOpenFilter}>
            Show results
          </Button>
        </Box>
      </Drawer>
      <SearchBanner />
      <Box sx={{ backgroundColor: '#f5f6f7' }}>
        <div className="big-container">
          <Box sx={{ padding: '40px 32px 56px', display: 'flex', gap: '24px', justifyContent: 'space-between' }}>
            <Box sx={{ width: '330px', display: 'none', '@media (min-width: 900px)': { display: 'block' } }}>
              <TimeshareFilters />
            </Box>
            <Box sx={{ width: '100%', '@media (min-width: 1041px)': { width: '684px' }, '@media (min-width: 1900px)': { width: '1041px' } }}>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <Title>Available rentals</Title>
                <Box flexGrow="1" />
                <Button
                  sx={{ display: 'none', '@media (min-width: 600px)': { display: 'inline-flex' }, '@media (min-width: 900px)': { display: 'none' }  }}
                  onClick={handleOpenFilter}
                  variant="outlined"
                  startIcon={<FilterListIcon />}
                >
                  Filters
                </Button>
                <IconButton
                  sx={{ display: 'inline-flex', '@media (min-width: 600px)': { display: 'none' }, color: getContent('theme').primaryColor, border: `1px solid rgba(${getContent('theme').primaryColorRgb}, 0.5)`, borderRadius: '4px'  }}
                  onClick={handleOpenFilter}
                >
                  <FilterListIcon />
                </IconButton>
              </Box>
              {isLoading ? (
                <Loader />
              ) : (
                <React.Fragment>
                  <Typography className={classes.subtitle}>{`${total} rentals found`}</Typography>
                  <Box sx={{ pb: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {Object.keys(params).map((key: string) => {
                      if (key === 'propertyId' || key === 'start' || key === 'end' || !params[key]) return null;
                      if (key === 'specialOfferOnly') return (
                        <Chip size="small" key={`${key}: ${params[key]}`} label="Only DashDeals" onDelete={() => onRemoveFilter(key)} />
                      )
                      if (typeof params[key] === 'string') {
                        return <Chip size="small" key={`${key}: ${params[key]}`} label={`${textFromCamelToNormalCase(key)}: ${params[key]}`} onDelete={() => onRemoveFilter(key)} />
                      } else if (typeof params[key] === 'boolean') {
                        return <Chip size="small" key={`${key}: ${params[key]}`} label={textFromCamelToNormalCase(key)} onDelete={() => onRemoveFilter(key)} />
                      } else {
                        return params[key].map((item: string) => (
                          <Chip
                            size="small"
                            key={item}
                            label={!isNaN(+item) ? destinationTypes.find(i => i.id === +item)?.name : item}
                            onDelete={() => onRemoveFilter(key, item)}
                          />
                        ))
                      }
                    })}
                  </Box>
                  <Box sx={{ pt: 2, pb: 2 }}>
                    {timeshares ? (
                      <Grid container spacing={2}>
                        {timeshares.map(timeshare => (
                          <Grid item key={timeshare.id} sx={{ maxWidth: '100%', flexBasis: '100%', '@media (min-width: 600px)': { flexBasis: '50%', maxWidth: '50%' }, '@media (min-width: 1900px)': { flexBasis: '33.333%', maxWidth: '33.333%' } }}>
                            <TimeshareCard timeshare={timeshare} />
                          </Grid>
                        ))}
                        {total > limit && (
                          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: 3, alignItems: 'center', pt: 2 }}>
                            <Typography className={classes.subtitle}>
                              {`Showing ${(page - 1) * limit + 1}–${page*limit <= total ? page*limit : total } of ${total}`}
                            </Typography>
                            <Pagination
                              count={Math.ceil(total / limit)}
                              onChange={(_:any, page: number) => onChangePage(page)}
                              page={page}
                              color="primary"
                            />
                          </Box>
                        )}
                      </Grid>
                    ) : (
                      <NoSearchData />
                    )}
                  </Box>
                  <ReservationRequestForm />
                </React.Fragment>
              )}
            </Box>
          </Box>
        </div>
      </Box>
    </React.Fragment>
  )
}

export default Timeshares;

const useStyles = makeStyles({
  title: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '-0.35px',
    color: 'rgba(0, 0, 0, 0.87)',
  }, 
  subtitle: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '48px',
    letterSpacing: '-0.4px',
    color: 'rgba(0, 0, 0, 0.6)',
  }
});
