import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Favicon from 'react-favicon';
import { stage } from 'config';
import theme from 'theme';
import dayjs from 'dayjs';
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import useDialog from 'hooks/useDialog';
// Async
import AuthAsync from 'store/auth/authAsync';
import MessagesAsync from 'store/messages/messagesAsync';
import NotificationsAsync from 'store/notifications/notificationsAsync';
import UsersAsync from 'store/users/usersAsync';
import ChatsAsync from 'store/chats/chatsAsync';
import AccountsAsync from 'store/accounts/accountsAsync';
// Selectors
import { selectIsAuthenticated } from 'store/auth/authSelectors';
import { selectCurrentUser } from 'store/users/usersSelectors';
import { selectAccountsSettings, selectSubscriptionModuleEnabled } from 'store/accounts/accountsSelectors';
import { selectError } from 'store/ui/uiSelectors';
// Actions
import { MessagesActions } from 'store/messages/messagesSlice';
import { AuthActions } from 'store/auth/authSlice';
import { UiActions } from 'store/ui/uiSlice';
// Components
import AppRouting from './App.routing';
import Notifications from 'components/Notifications';
import Header from 'components/Header';
import Footer from 'components/Footer';
import GoogleAnalytics from 'components/GoogleAnalytics';
import SquarePayment from 'components/SquarePayment';
import SubscriptionPackages from 'components/SubscriptionPackages';
// Types
import UserRoles from 'types/UserRoles';
import SubscriptionStatus from 'types/SubscriptionStatus';
// Mui
import { CssBaseline, Box, LinearProgress } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
// Utilities
import { getContent, getDomain } from 'utilities/Utilities';

const App:React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const currentUser = useAppSelector(selectCurrentUser);
  const accountsSettings = useAppSelector(selectAccountsSettings);
  const subscriptionModuleEnabled = useAppSelector(selectSubscriptionModuleEnabled);
  const error = useAppSelector(selectError);

  const { Dialog:PaymentDialog, openDialog:openPaymentDialog, closeDialog:closePaymentDialog } = useDialog();
  const { Dialog:SubscriptionDialog, openDialog:openSubscriptionDialog, closeDialog:closeSubscriptionDialog } = useDialog();

  const [selectedSubscriptionPackageId, setSelectedSubscriptionPackageId] = useState<number | null>(null);

  const selectSubscriptionPackage = (subscriptionPackageId: number) => {
    setSelectedSubscriptionPackageId(subscriptionPackageId);
    closeSubscriptionDialog();
    openPaymentDialog();
  }

  useEffect(() => {
    dispatch(AuthAsync.checkIsUserAuthenticated({}));
    dispatch(AccountsAsync.getByDomain(getDomain()));

    // set meta tags
    document.title = getContent('common').title;
    document.querySelector('meta[name="description"]')?.setAttribute("content", getContent('common').description);
    // set manifest
    const stringManifest = JSON.stringify(getContent('manifest'));
    const blob = new Blob([stringManifest], {type: 'application/json'});
    const manifestURL = URL.createObjectURL(blob);
    document.querySelector('#manifest')?.setAttribute('href', manifestURL);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      // connect
      dispatch(MessagesActions.connectMessages());
      // fetch
      dispatch(MessagesAsync.fetchMessages({ size: 50 }));
      dispatch(NotificationsAsync.fetchNotifications({ size: 50 }));
    } else {
      dispatch(MessagesActions.disconnectMessages());
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    if (accountsSettings.chatModuleEnabled && isAuthenticated) {
      dispatch(ChatsAsync.fetchChats({ size: 100 }));
    }
    // eslint-disable-next-line
  }, [accountsSettings.chatModuleEnabled, isAuthenticated])

  useEffect(() => {
    if (currentUser && currentUser.activeRole === UserRoles.Owner && pathname === '/reservation-requests') {
      navigate('/my-timeshares')
    }
    if (currentUser && (pathname === '/my-timeshares' || pathname === '/reservations')) {
      navigate(currentUser.activeRole === UserRoles.Owner ? subscriptionModuleEnabled ? '/benefits' : '/my-timeshares' : '/reservations');
    }
    if (currentUser && !currentUser?.roles.includes(currentUser.activeRole)) {
      dispatch(UsersAsync.switchRole(currentUser?.roles[0]));
    }
    // eslint-disable-next-line
  }, [currentUser]);

  const subscriptionStatusActive = currentUser?.subscriptions?.some((subscription) => subscription.status === SubscriptionStatus.Active);
  const subscriptionStatusTrial = currentUser?.subscriptions?.find((subscription) => subscription.status === SubscriptionStatus.Trial);

  const subscriptionStatusTrialExpired: boolean = subscriptionStatusTrial 
    ? dayjs(subscriptionStatusTrial.startDate).add(subscriptionStatusTrial.subscriptionPackage.trialPeriodDaysNumber, 'day') < dayjs()
    : false;

  useEffect(() => {
    if (!currentUser) return;

    if (
      subscriptionModuleEnabled
        && currentUser.activeRole === UserRoles.Owner && currentUser.roles.includes(UserRoles.Owner)
        && (!currentUser.subscriptions || (!subscriptionStatusActive && !subscriptionStatusTrial) 
          || (subscriptionStatusTrial && subscriptionStatusTrialExpired))
    ) {
      
      dispatch(AuthActions.setAuthenticated(false));
      if ((!subscriptionStatusActive && !subscriptionStatusTrial) || !currentUser.subscriptions) {
        openSubscriptionDialog();
      } else {
        openPaymentDialog();
      }
    } else {
      dispatch(AuthActions.setAuthenticated(true));
      dispatch(UiActions.setError(false));
      if (!error) dispatch(AccountsAsync.fetchAccountsSetting({}));
    }
    // eslint-disable-next-line
  }, [currentUser, error]);

  if ( isAuthenticated === null || subscriptionModuleEnabled === null ) return <LinearProgress />;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme(getContent('theme'))}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column'
        }}>
          {accountsSettings.subscriptionSquareApplicationId && (
            <PaymentDialog maxWidth="sm">
              <SquarePayment
                onClose={closePaymentDialog}
                applicationId={accountsSettings.subscriptionSquareApplicationId}
                locationId={accountsSettings.subscriptionSquareLocationId}
                selectedSubscriptionPackageId={selectedSubscriptionPackageId}
                expired={(subscriptionStatusTrial && subscriptionStatusTrialExpired)}
              />
            </PaymentDialog>
          )}
          <SubscriptionDialog maxWidth="sm">
            <SubscriptionPackages
              setSubscriptionPackageId={currentUser?.subscriptions ? selectSubscriptionPackage : null}
              onClose={closeSubscriptionDialog}
              expired={!!currentUser?.subscriptions}
            />
          </SubscriptionDialog>
          <Favicon url={getContent('common').faviconSrc} />
          <CssBaseline />
          <Header />
          <Box sx={{ minHeight: 'calc(100vh - 266px)', '@media (min-width: 1240px)': { minHeight: 'calc(100vh - 236px)' } }}>
            <AppRouting />
          </Box>
          <Footer />
          <Notifications />
          {stage === 'production' && getContent('settings').gaId && (
            <GoogleAnalytics gaId={getContent('settings').gaId} />
          )}
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
