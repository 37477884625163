import React from "react";
// components
import ReservationRequestForm from "components/ReservationRequest.form";
import GetInTouch from "components/GetInTouch";
// MUI
import { Box, Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const ContactUsPage:React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.block}>
      <div className="container">
        <ReservationRequestForm />
        <Divider sx={{ pt: 4, pb: 4 }}>
          <Typography sx={{ color: 'rgba(0, 0, 0, 0.38)' }}>OR</Typography>
        </Divider>
        <GetInTouch />
      </div>
    </Box>
  )
}

export default ContactUsPage;

const useStyles = makeStyles({
  block: {
    backgroundColor: '#EBEEF0',
    padding: '16px',
    '@media (min-width: 600px)': {
      padding: '32px',
    },
    '@media (min-width: 1240px)': {
      padding: '40px 0',
    },
  },
});
