import { FC, useCallback, useEffect } from "react";
// Redux
import { useAppDispatch, useAppSelector } from "hooks/redux";
import RegionsAsync from "store/regions/regionsAsync";
import { selectLocationsByCountryName } from "store/regions/regionsSelectors";
import ILocation from "models/Location";
// MI
import { makeStyles } from "@mui/styles";
import { Accordion, AccordionDetails, AccordionSummary, Box, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
// Components
import Title from "components/Title";
// Utilities
import { openWindow } from "utilities/Utilities";

const lineHeight = 21;
const gap = 8;
const paddings = 24;
 
const Locations:FC = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const isTablet = useMediaQuery('@media (min-width: 600px)');
  const isLaptop = useMediaQuery('@media (min-width: 900px)');

  const locations:Record<string, ILocation[]> | null = useAppSelector(selectLocationsByCountryName);

  const getHeight = useCallback((elementsCount: number) => {
    const columns = isLaptop ? 4 : isTablet ? 2 : 1;
    const maxElementsInColumn = Math.ceil(elementsCount / columns);
    const columnHeight = (maxElementsInColumn * lineHeight) + ((maxElementsInColumn - 1) * gap) + paddings;
    return `${columnHeight}px`;
  }, [isTablet, isLaptop]);

  useEffect(() => {
    dispatch(RegionsAsync.fetchLocations());
    // eslint-disable-next-line
  }, []);

  if (!locations) return null;
  return (
    <Box className={classes.block}>
      <div className="big-container">
        <Title>More trending destinations</Title>
        <Box sx={{ pt: 2, pb: 4 }}>
          {Object.keys(locations).map((country, index) => (
            <Accordion key={country} disableGutters defaultExpanded={index === 0} sx={{
              background: 'none',
              boxShadow: 'none',
              '&:not(:last-child)': {
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
              },
              '&:before': {
                display: 'none'
              }
            }}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`${country}-regions`}
                id={`${country}-regions`}
                sx={{ fontWeight: 'bold' }}
              >
                {`${country} vacation rentals`}
              </AccordionSummary>
              <AccordionDetails sx={{
                height: getHeight(locations[country].length),
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                alignContent: 'flex-start',
                gap: '8px 24px'
              }}>
                {locations[country].map(region => (
                  <Box
                    key={region.regionNameSlug}
                    sx={{ width: { xs: '100%', sm: 'calc(50% - 12px)', md: 'calc(25% - 18px)' } }}
                  >
                    <Tooltip title={region.regionName}>
                      <Typography
                        onClick={() => openWindow(`/timeshares/${region.countryNameSlug}/${region.regionNameSlug}`)}
                        className={classes.link}
                      >
                        {region.regionName}
                      </Typography>
                    </Tooltip>
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </div>
    </Box>
  );
}
 
export default Locations;

const useStyles = makeStyles({
  block: {
    padding: '16px 0 0 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f5f6f7',
    gap: '24px',
    '@media (min-width: 600px)': {
      padding: '24px 0 0 24px',
    },
    '@media (min-width: 1240px)': {
      padding: '32px 0 0',
    },
  },
  link: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px',
    color: '#3D98BF',
    cursor: 'pointer',
    transition: 'color 0.3s',
    '&:hover': {
      color: '#53B8E0'
    }
  }
});
