import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
// Hooks
import { useAppSelector } from 'hooks/redux';
// Types
import UserRoles from 'types/UserRoles';
// Selectors
import { selectCurrentUser } from 'store/users/usersSelectors';
import { selectGuestModuleEnabled, selectSubscriptionModuleEnabled } from 'store/accounts/accountsSelectors';
// Components
import PublicRoute from 'components/PublicRoute';
import PrivateRoute from 'components/PrivateRoute';
// pages
import ProfilePage from 'pages/ProfilePage';
import Timeshares from 'pages/Timeshares';
import TimeshareDetails from 'pages/Timeshares/TimeshareDetails';
import HomePage from 'pages/Home';
import ListYourPropertyPage from 'pages/ListYourProperty';
import ReservationRequestSubmitted from 'pages/ReservationRequestSubmitted';
import MyTimeshares from 'pages/MyTimeshares';
import ReservationsHistory from 'pages/ReservationsHistory';
import ReservationRequests from 'pages/ReservationRequests';
import ContactUsPage from 'pages/Home/ContactUsPage';
import MessagesPage from 'pages/Messages';
import NotificationsPage from 'pages/Notifications';
import AboutPage from 'pages/About';
import ChatsPage from 'pages/Chats';
import ChatsMessagesPage from 'pages/ChatsMessages';
import UnsubscribePage from 'pages/Unsubscribe';
import SubscriptionsList from 'pages/SubscriptionsList';
import PollsPage from 'pages/Polls';
import BenefitsList from 'pages/BenefitsList';
import BenefitRequestsList from 'pages/BenefitRequestsList';
import LocationPage from 'pages/LocationPage';
import PropertyPage from 'pages/PropertyPage';
// core.vote
import PrivacyPolicyCore from 'pages/core.vote_PrivacyPolicy';
import TermsConditionsCore from 'pages/core.vote_TermsConditions';
// dashweek.com
import PrivacyPolicyDash from 'pages/dashweek.com_PrivacyPolicy';
import TermsConditionsDash from 'pages/dashweek.com_TermsConditions';
// Utilities
import { getDomain } from 'utilities/Utilities';

const domain = getDomain();

const AppRouting:React.FC = () => {
  const subscriptionModuleEnabled = useAppSelector(selectSubscriptionModuleEnabled);
  const guestModuleEnabled = useAppSelector(selectGuestModuleEnabled);
  const currentUser = useAppSelector(selectCurrentUser);
  const navigatePath = currentUser ? currentUser.activeRole === UserRoles.Owner ? subscriptionModuleEnabled ? '/benefits' :'/my-timeshares' : '/reservations' : '/';

  const PrivacyPolicy = domain.includes('dashweek') ? PrivacyPolicyDash : PrivacyPolicyCore;
  const TermsConditions = domain.includes('dashweek') ? TermsConditionsDash : TermsConditionsCore;

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      {guestModuleEnabled && (
        <>
          <Route path="timeshares" element={<Timeshares />} />
          <Route path="timeshares/id/:timeshareId" element={<TimeshareDetails />} />
          <Route path="timeshares/:countryNameSlug">
            <Route index element={<LocationPage />} />
            <Route path=":regionNameSlug" element={<LocationPage />} />
          </Route>
          <Route path="property/:propertyNameSlug" element={<PropertyPage />} />
        </>
      )}
      <Route path="list-your-property" element={
        <PublicRoute>
          <ListYourPropertyPage />
        </PublicRoute>
      } />
      <Route path="register" element={
        <PublicRoute>
          <RegisterPage>
            <HomePage />
          </RegisterPage>
        </PublicRoute>
      } />
      <Route path="recover-password" element={
        <PublicRoute>
          <NewPasswordPage>
            <HomePage />
          </NewPasswordPage>
        </PublicRoute>
      } />
      <Route path="profile" element={
        <PrivateRoute>
          <ProfilePage />
        </PrivateRoute>
      } />
      <Route path="my-timeshares" element={
        <PrivateRoute>
          <MyTimeshares />
        </PrivateRoute>
      } />
      {guestModuleEnabled && (
        <Route path="reservations" element={
          <PrivateRoute>
            <ReservationsHistory />
          </PrivateRoute>
        } />
      )}
      <Route path="reservation-requests" element={
        <PrivateRoute>
          <ReservationRequests />
        </PrivateRoute>
      } />
      <Route path="helpdesk" element={
        <PrivateRoute>
          <MessagesPage />
        </PrivateRoute>
      } />
      <Route path="chats" element={
        <PrivateRoute>
          <ChatsPage />
        </PrivateRoute>
      } />
      <Route path="chats/:chatId" element={
        <PrivateRoute>
          <ChatsMessagesPage />
        </PrivateRoute>
      } />
      <Route path="notifications" element={
        <PrivateRoute>
          <NotificationsPage />
        </PrivateRoute>
      } />
      <Route path="subscriptions" element={
        <PrivateRoute>
          <SubscriptionsList />
        </PrivateRoute>
      } />
      <Route path="benefits" element={
        <PrivateRoute>
          <BenefitsList />
        </PrivateRoute>
      } />
      <Route path="benefit-claims" element={
        <PrivateRoute>
          <BenefitRequestsList />
        </PrivateRoute>
      } />
      <Route path="polls" element={
        <PrivateRoute>
          <PollsPage />
        </PrivateRoute>
      } />

      <Route path="about-us" element={<AboutPage />} />
      {guestModuleEnabled && <Route path="contact-us" element={<ContactUsPage />} />}
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="terms-and-conditions" element={<TermsConditions />} />
      <Route path="submitted-request" element={<ReservationRequestSubmitted />} />

      <Route path="email-subscription-unsubscribe" element={<UnsubscribePage />} />
      
      <Route path="*" element={<Navigate to={navigatePath} />} />
    </Routes>
  )
}

export default AppRouting;

const RegisterPage:React.FC<any> = ({ children }) => {
  return <React.Fragment>{children}</React.Fragment>
}
const NewPasswordPage:React.FC<any> = ({ children }) => {
  return <React.Fragment>{children}</React.Fragment>
}