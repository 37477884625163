import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
// Async
import PropertiesAsync from "store/properties/propertiesAsync";
// Selectors
import { selectAllProperties } from "store/properties/propertiesSelectors";
import { selectParams } from "store/timeshares/timesharesSelectors";
import { selectGuestModuleEnabled } from "store/accounts/accountsSelectors";
// Actions
import { TimesharesActions } from "store/timeshares/timesharesSlice";
import { PropertiesActions } from "store/properties/propertiesSlice";
// MUI
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Autocomplete, Box, Button, debounce, Grid, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
// Utilities
import { isFieldRequired, isValidDate } from "utilities/Validation";
import { getContent } from 'utilities/Utilities';

interface IFormData {
  propertyId?: any;
  start?: any;
  end?: any;
  search?: string;
}

const SearchBanner:React.FC = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const  { logo, title, subtitle, inputPlaceholder, images } = getContent('searchBanner');

  const isHomePage:boolean = pathname === '/';

  const [ativeBannerImg, setActiveBannerImg] = useState(1);

  const guestModuleEnabled = useAppSelector(selectGuestModuleEnabled);
  const params = useAppSelector(selectParams);
  const properties = useAppSelector(selectAllProperties) || [];
  const [isLoadingProperties, setIsLoadingProperties] = useState<boolean>(false);

  const { control, handleSubmit, setValue, watch, reset, formState: { errors } } = useForm<IFormData>({
    defaultValues: {
      propertyId: null,
      start: null,
      end: null,
      search: '',
    }
  })

  useEffect(() => {
    reset({
      propertyId: params.propertyId ? params.propertyId : params.search ? params.search : null,
      start: params?.start || null,
      end: params?.end || null,
    })
    // eslint-disable-next-line
  }, [params])

  const startDate = watch('start');
  const propertyWatcher = watch('propertyId');

  const onSubmit = handleSubmit((data: IFormData) => {
    const { start, end, propertyId } = data;
    const nextData: IFormData = {};
    nextData['start'] = start ? dayjs(start).format('YYYY-MM-DD') : null;
    nextData['end'] = end ? dayjs(end).format('YYYY-MM-DD') : null;
    if (propertyId && propertyId.id) {
      nextData['propertyId'] = propertyId;
      nextData['search'] = '';
    } else {
      nextData['propertyId'] = null;
      nextData['search'] = propertyId;
    }

    dispatch(TimesharesActions.setParams({ ...params, ...nextData }));
    if (!pathname.includes('timeshares')) {
      navigate('timeshares');
    }
  })

  // eslint-disable-next-line
  const debounceProperty = useCallback(debounce((search:string) => {
    if (search.trim()) {
      setIsLoadingProperties(true);
      dispatch(PropertiesAsync.fetchProperties({ search, size: 50 }))
        .unwrap()
        .finally(() => setIsLoadingProperties(false));
    } else {
      dispatch(PropertiesActions.clearProperties());
    }
  }, 500), []);

  const onChangeProperty = (event:any) => {
    debounceProperty(event.target.value);
  }

  useEffect(() => {
    const bgInterval = setInterval(() => {
      setActiveBannerImg(prev => prev < images.length ? prev + 1 : 1);
    }, 7000)

    return () => {
      clearInterval(bgInterval);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (propertyWatcher && propertyWatcher.id) {
      navigate(`/property/${propertyWatcher.nameSlug}`);
    }
    // eslint-disable-next-line
  }, [propertyWatcher]);

  return (
    <Box sx={{ position: 'relative', padding: { xs: '16px', sm: '32px', md: isHomePage ? '64px 32px' : '32px' } }}>
      {images.map((image: string, index: number) => (
        <img
          key={image} alt=""
          src={`${image}`} className={classes.bannerImg} style={{ opacity: ativeBannerImg === (index + 1) ? 1 : 0 }}
        />
      ))}
      <div className="big-container">
        {title && (
          <Typography className={classes.title} sx={{ display: isHomePage ? 'block' : 'none' }}>{title}</Typography>
        )}
        <Box sx={{ display: isHomePage ? 'flex' : 'none', alignItems: 'center', gap: 1 }}>
          {logo && (
            <img src={logo} alt="" className={classes.logo} />
          )}
          <Typography className={classes.subtitle}>{subtitle}</Typography>
        </Box>
        {guestModuleEnabled && (
          <form onSubmit={onSubmit} noValidate className={classes.form} style={{ paddingTop: !isHomePage ? 0 : 'auto' }}>
            <Grid container spacing={1}>

              <Grid item xs={12} lg={4} xl={6}>
                <Controller
                  control={control} name="propertyId"
                  rules={{ required: isFieldRequired }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      sx={{ backgroundColor: '#fff', borderRadius: '4px' }}
                      disablePortal
                      id="search-banner-select-propertyId"
                      options={properties}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      getOptionLabel={(value: any) => value.name ? value.name : value}
                      value={value || null}
                      onChange={(_:any, value:any) => {
                        onChange(value);
                        debounceProperty('');
                      }}
                      loading={isLoadingProperties}
                      loadingText="Search..."
                      noOptionsText=""
                      forcePopupIcon={false}
                      filterOptions={options => options}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id} style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                          {option.name}
                        </li>
                      )}
                      className={errors.propertyId ? classes.searchInput : ''}
                      renderInput={(params) => 
                        <TextField
                          {...params}
                          onChange={(e) => {
                            onChangeProperty(e);
                            onChange(e.target.value);
                          }}
                          placeholder={inputPlaceholder}
                          error={Boolean(errors.propertyId)}
                          inputProps={{
                            ...params.inputProps,
                            sx: { color: errors.propertyId ? '#d32f2f' : 'initial' }
                          }}
                        />
                      }
                    />
                  )}
                />
              </Grid>
            
              <Grid item xs={6} sm={4} lg={3} xl={2}>
                <Controller 
                  control={control} name="start"
                  rules={{ validate: {
                    isValid: (date: Dayjs | null) => isValidDate(date),
                  } }}
                  render={({ field: { value, onChange } }) => (
                    <DesktopDatePicker
                      minDate={dayjs()}
                      value={value ? dayjs(value) : null}
                      onChange={(date: any) => {
                        onChange(date);
                        setValue('end', date ? dayjs(date).add(7, 'day') : null);
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          sx: { backgroundColor: '#fff', borderRadius: '4px' },
                          placeholder: 'Start date',
                          error: !!errors.start
                        },
                        actionBar: {
                          actions: ['clear', 'accept'],
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6} sm={4} lg={3} xl={2}>
                <Controller 
                  control={control} name="end"
                  rules={{ validate: {
                    isValid: (date: Dayjs | null) => isValidDate(date),
                  } }}
                  render={({ field: { value, onChange } }) => (
                    <DesktopDatePicker
                      minDate={dayjs()}
                      value={value ? dayjs(value) : null}
                      onChange={(date: any) => {
                        onChange(date);
                        if (!startDate) {
                          setValue('start', date ? dayjs(date).subtract(7, 'day') : null);
                        }
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          sx: { backgroundColor: '#fff', borderRadius: '4px' },
                          placeholder: 'End date',
                          error: !!errors.end
                        },
                        actionBar: {
                          actions: ['clear', 'accept'],
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              
              <Grid item xs={12} sm={4} lg={2}>
                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{ fontSize: '20px', height: '100%' }}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </div>
    </Box>
  );
}

export default SearchBanner;

const useStyles = makeStyles({
  bannerImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: -1,
    transition: 'opacity 2s',
  },
  logo: {
    width: '40px',
    objectFit: 'contain',

    '@media (min-width: 600px)': {
      width: '50px',
    },
    '@media (min-width: 1240px)': {
      width: '75px',
      marginTop: '-7px',
    },
  },
  title: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '133.4%',
    letterSpacing: '-0.5px',
    color: '#fff',
    '@media (min-width: 600px)': {
      fontSize: '32px',
      lineHeight: '116.7%',
    },
    '@media (min-width: 1240px)': {
      fontSize: '46px',
      lineHeight: '120%',
      letterSpacing: '-1px',
    },
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '-0.35px',
    color: '#fff',
    '@media (min-width: 600px)': {
      fontSize: '20px',
      lineHeight: '160%',
    },
    '@media (min-width: 1240px)': {
      fontSize: '24px',
      lineHeight: '133.4%',
      letterSpacing: '-0.5px',
    },
  },
  form: {
    paddingTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '@media (min-width: 600px)': {
      paddingTop: '24px',
    },
    '@media (min-width: 900px)': {
      flexDirection: 'row',
    },
    '@media (min-width: 1240px)': {
      paddingTop: '48px',
    },
  },
  searchInput: {
    animation: '$shake 0.13s 3',
  },
  '@keyframes shake': {
    '0%': {
      transform: 'translateX(0)',
    },
    '40%': {
      transform: 'translateX(-4px)',
    },
    '100%': {
      transform: 'translateX(4px)',
    },
  },
});
