import React, { useEffect } from "react";
// mui
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const TermsConditions:React.FC = () => {
  const classes = useStyles();

  const scrollToTop = () => { window.scrollTo({ top: 0, behavior: 'smooth' }) };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className={[classes.page, 'container'].join(' ')}>
      <Typography className={classes.header}>Core Vote Terms and Conditions</Typography>

      <Typography className={classes.text}>
        By using or accessing Core Vote.com, a subdomain of any such websites, any mobile application for such websites or any other website operated by us on which these Terms and Conditions are posted via a link or otherwise (each referred to herein as a “Site”), you acknowledge and agree that you are subject to the following terms and conditions, as well as our Privacy Policy, which also governs your use of the Site, and is incorporated by reference (These Terms and Conditions with the Privacy Policy shall be referred to as the “Terms“). Please read these Terms carefully, as they contain important information about limitations of liability and resolution of disputes through arbitration rather than court.
      </Typography>

      <Typography className={classes.text}>
        A user’s reservation or use of a Core Vote site is bound by the terms and conditions on the Core Vote site where the reservation is finalized (and not necessarily the Core Vote site on which the property was originally listed). If there are any conflicts between the terms and conditions of the Core Vote site you found the property on and the terms and conditions of the Core Vote site you finalize a booking on, the terms and conditions of the Core Vote site where the reservation is finalized will govern. If you do not fully agree to these Terms and any other terms and conditions posted or linked to any Site, you are not authorized to access or otherwise use the Site. Under these Terms, “use“ or “access“ of the Site specifically includes any direct or indirect access or use of the Site or any cached version of the Site and any direct or indirect access or use of any information or content on the Site, regardless of how obtained and the term “Site“ includes, without limitation, any cached version thereof.
      </Typography>

      <Typography className={classes.text}>
        Each Site is operated by Core Vote. Unless otherwise specified, the entity controlling the Site you are accessing is referred to herein as “Core Vote,” “we,” “us” or “our”. The term “you“ refers to the user visiting the Site, listing a property, and/or requesting a reservation on this Site, or our customer service agents. You should read through all the Terms carefully. The Terms constitute a legally binding agreement between you and Core Vote. You are not authorized to use this Site unless you are at least 18 years of age and able to enter into legally binding contracts. We do not knowingly collect the information of anyone under the age of 18. If you arrived on the Site after having been re-directed or otherwise clicking on another website, you agree that these Terms shall govern your use of this Site.
      </Typography>

      <Typography className={classes.title}>1. The Site is a Venue and We are Not a Party to any Rental Agreement or other Transaction Between Users of the Site.</Typography>
      <Typography className={classes.text}>
        We urge all users to be responsible about their use of this Site and any transaction entered into as a result of either listing a property or renting a property. We do not own or manage, nor can we contract for, any vacation rental property listed on a Site. The Site provides an on-line marketplace to allow homeowners and property managers who advertise on the Site (each, a “member”) to offer for rent in a variety of pricing formats, a specific vacation or short term rental property to potential tenants or tenants (each, a “traveler” and, collectively with a member, the “users”). “Members“ may also include property owners or managers who originally advertised their properties on another website and their listings have been redistributed on the Site. We also may offer online booking or other tools or services to allow users to communicate with each other and enter into rental agreements or other transactions. 
      </Typography>
      <Typography className={classes.text}>
        We are not a party to any rental or other agreement between users. This is true even if the Site allows you to book a rental or provides other ancillary products or services, as the Site may facilitate booking a rental or other tools, services or products, but we are not a party to any rental or other agreement between users. As a result, any part of an actual or potential transaction between a traveler and a member, including the quality, condition, safety or legality of the properties advertised, the truth or accuracy of the listings (including the content thereof or any review relating to any traveler or property), the ability of members to rent a vacation property or the ability of travelers to contract for properties are solely the responsibility of each user. You acknowledge and agree that you may be required to enter into one or more separate agreements, waivers or terms and conditions before making a booking or purchasing a product or service and Core Vote or one of its affiliates may place additional restrictions on your booking, product or service. 
      </Typography>
      <Typography className={classes.text}>
        Users agree that they are responsible for, and agree to abide by, all laws, rules and regulations applicable to their use of the Site, their use of any tool, service or product offered on the Site and any transaction they enter into on the Site or in connection with their use of the Site.
      </Typography>
      <Typography className={classes.text}>
        Members further agree that they are responsible for and agree to abide by all laws, rules, ordinances, or regulations applicable to the listing of their rental property and the conduct of their rental business, including but not limited to any and all laws, rules, ordinances, regulations or other requirements relating to taxes, credit cards, data and privacy, permits or license requirements, zoning ordinances, safety compliance and compliance with all anti-discrimination and fair housing laws, as applicable. Please be aware that, even though we are not a party to any rental transaction and assume no liability for legal or regulatory compliance pertaining to rental properties listed on the Site, there may be circumstances where we are nevertheless legally obligated (as we may determine in our sole discretion) to provide information relating to your listing in order to comply with requests from governmental bodies in relation to investigations, litigation or administrative proceedings, and we may choose to comply with such obligations in our sole discretion.
      </Typography>
      <Typography className={classes.text}>
        Members who accept credit card, banking or other payment information from travelers agree to properly handle and safeguard all such information in accordance with applicable legal and regulatory requirements and best practices.
      </Typography>
      <Typography className={classes.text}>
        Although most travel is completed without a serious incident, travel to some destinations may involve more risk than others. We urge travelers to research the location they wish to visit and to review travel prohibitions, warnings, announcements and advisories issued by the United States Government before booking.
        Information may be found at <a href="https://www.state.gov/" target="_blank" rel="noreferrer">www.state.gov</a>, <a href="https://www.tsa.gov/" target="_blank" rel="noreferrer">www.tsa.gov</a>, <a href="https://www.dot.gov/" target="_blank" rel="noreferrer">www.dot.gov</a>, <a href="https://www.faa.gov/" target="_blank" rel="noreferrer">www.faa.gov</a>, <a href="https://www.cdc.gov/" target="_blank" rel="noreferrer">www.cdc.gov</a>, <a href="https://www.treas.gov/ofac" target="_blank" rel="noreferrer">www.treas.gov/ofac</a> and <a href="https://www.customs.gov/" target="_blank" rel="noreferrer">www.customs.gov</a>.
      </Typography>
      <Typography className={classes.text}>
        While we do take certain measures with a goal to assist users to avoid potentially fraudulent or other illegal activity of which we become aware, we assume no liability or obligation to take any such measures or actions. When we provide warnings or messages to users about any such activity, we do not warrant that such messages are accurate or that such messages will reach any or all users they should have reached in a timely manner or at all or that such messages or measures will prevent any harm or otherwise have any impact. 
      </Typography>

      <Typography className={classes.title}>2. Limited License to Use the Site.  </Typography>
      <Typography className={classes.text}>
        Users are granted a limited, revocable non-exclusive license to access the Site and the content and services provided on the Site solely for the purpose of advertising a property, searching for a property, purchasing or researching (for the purpose of inquiring about purchasing) any of the products or services offered on any Site, participating in an interactive area hosted on the Site or for any other purpose clearly stated on a Site, all in accordance with the Terms. Any use of the Site that is not for one of these purposes or otherwise in accordance with the Terms or as otherwise authorized by us in writing is expressly prohibited.
      </Typography>

      <Typography className={classes.title}>3. Unauthorized Uses of the Site. </Typography>
      <Typography className={classes.text}>
        The license to use the Site only extends to the uses expressly described herein. The license to use the Site granted to users in these Terms does not include any right of collection, aggregation, copying, scraping, duplication, display or any derivative use of the Site nor any right of use of data mining, robots, spiders or similar data gathering and extraction tools without our prior written permission; provided, however, that a limited exception from the foregoing exclusion is provided to general purpose internet search engines that use tools to gather information for the sole purpose of displaying hyperlinks to the Site, provided they each do so from a stable IP address or range of IP addresses using an easily identifiable agent and comply with our robots.txt file. “General purpose internet search engines” do not include a website or search engine or other service that provides classified listings or property rental advertisements, or any subset of the same or which is in the business of providing vacation property rental services or other services that compete with us.
      </Typography>
      <Typography className={classes.text}>
        Unauthorized uses of the Site also include, without limitation, those listed below. You agree not to do any of the following, unless otherwise previously and specifically agreed to by us:
      </Typography>
      <ul className={classes.text}>
        <li>Any commercial use of the Site or any content on the Site, other than by members in good standing, or by members under a valid license to software offered on the Site</li>
        <li>Any use of the Site or the tools and services on the Site for the purpose of booking or soliciting a rental of a property other than a property listed under a valid subscription or pay-per-booking product</li>
        <li>Copy, reproduce, upload, post, display, republish, distribute or transmit any part of the content in any form whatsoever</li>
        <li>Reproduce any portion of the Site on your website or otherwise, using any device including, but not limited to, use of a frame or border environment around the Site, or other framing technique to enclose any portion or aspect of the Site, or mirror or replicate any portion of the Site</li>
        <li>Deep-link to any portion of the Site without our express written permission</li>
        <li>Modify, translate into any language or computer language or create derivative works from, any content or any part of the Site</li>
        <li>Reverse engineer any part of the Site</li>
        <li>Sell, offer for sale, transfer or license any portion of the Site in any form to any third parties</li>
        <li>Use the Site and its inquiry or booking functionality other than to advertise and/or research vacation rentals, to make legitimate inquiries to our members or any other use expressly authorized on the Site</li>
        <li>Use the Site to post or transmit information that is in any way false, fraudulent, or misleading, including making any reservation or inquiry under false pretenses, or taking any action that may be considered phishing or that would give rise to criminal or civil liability</li>
        <li>Post or transmit any unlawful, threatening, abusive, libelous, defamatory, obscene, vulgar, indecent, inflammatory, sexually explicit, pornographic or profane material</li>
        <li>Violate, plagiarize or infringe the rights of us or third parties including, without limitation, copyright, trademark, patent, trade secrets, rights of publicity or privacy or any other intellectual or proprietary rights</li>
        <li>Use or access the Site in any way that, in our sole discretion, adversely affects, or could adversely affect, the performance or function of the Site or any other system used by us or the Site.</li>
      </ul>
      <Typography className={classes.text}>
        If you are aware of, or experience, any content, activity or communication through or in connection with the Site that appears to be in violation of the above restrictions, or in violation of any other provision of these Terms, we ask that you please inform us by contacting us as set forth under “Contact Us,“ below.
      </Typography>

      <Typography className={classes.title}>4. Proprietary Rights and Downloading of Information from the Site.</Typography>
      <Typography className={classes.text}>
        The Site and all content and information on the Site are protected by copyright as a collective work and/or compilation, under applicable U.S. and international copyright laws and conventions and database rights. You agree to abide by any and all copyright notices, information or restrictions contained in or relating to any content on the Site. Copying, storing or otherwise accessing the Site or any content on the Site other than for your personal, noncommercial use (other than in accordance with a valid listing) is expressly prohibited without prior written permission from us. As part of the rental inquiry or reservation process, for your own personal, noncommercial use and not for further distribution, you may download, display and/or print one copy of any portion of the Site. You may not modify the same, and you must reproduce our copyright notice in the form displayed on the relevant portion(s) of the Site that you desire to download, display or print.
      </Typography>

      <Typography className={classes.title}>5. Your E-mail Address and Data; Our Privacy Policy; Data Transmittal.</Typography>
      <Typography className={classes.text}>
        When you provide your e-mail address, name or other information to us in connection with your use or access to the Site, any service or tool provided on the Site or otherwise, you agree to allow the Site and its affiliated websites to add your e-mail address, name or other information provided to our database of users. You may receive one or more promotional e-mails from either the Site or a website of one of Core Vote’s affiliates. You are welcome to opt not to receive such promotional e-mails from the Site or such affiliates’ websites at any time. Please review our Privacy Policy for more information regarding our email and other data collection practices and safeguards, and how to opt not to receive such emails. Your use of the Site signifies your acknowledgment of, and agreement with, our Privacy Policy. We adhere to strong principles of privacy. You agree that we may access and use your user-contributed content in accordance with these Terms or our Privacy Policy and we agree that we will only disclose your user-contributed content in accordance with these Terms and our Privacy Policy. 
      </Typography>

      <Typography className={classes.title}>6. Identity Verification.</Typography>
      <Typography className={classes.text}>
        User verification on the Internet is difficult and we cannot, and do not assume any responsibility for, the confirmation of each user's purported identity. We encourage you to communicate directly with a traveler or member through the tools available on the Site, though even this does not assure you of the identity of the person with which you are communicating. We further encourage you to take other reasonable measures to assure yourself of the other person’s identity and, for travelers, of the property and relevant details of your booking or proposed booking.
      </Typography>
      <Typography className={classes.text}>
        You agree to (i) keep your password and online ID for both your account with us and your email account secure and strictly confidential, providing it only to authorized users of your accounts, (ii) instruct each person to whom you give your online ID and password that he or she is not to disclose it to any unauthorized person, (iii) notify us immediately and select a new online ID and password if you believe your password for either your account with us or your email account may have become known to an unauthorized person, and (iv) notify us immediately if you are contacted by anyone requesting your online ID and password. Further, if we suspect any unauthorized access to your account, upon our request, you agree to promptly change your ID and password and take any other related action as we may reasonably request.
      </Typography>
      <Typography className={classes.text}>
        We discourage you from giving anyone access to your online ID and password for your account with us and your email account. However, if you do give someone your online ID and online password, or if you fail to adequately safeguard such information, you are responsible for any and all transactions that the person performs while using your account with us and/or your email account, even those transactions that are fraudulent or that you did not intend or want performed.  
      </Typography>
      <Typography className={classes.text}>
        EACH USER ACKNOWLEDGES AND AGREES THAT: (1) NEITHER Core Vote NOR ANY OF ITS AFFILIATES WILL HAVE ANY LIABILITY TO ANY USER FOR ANY UNAUTHORIZED TRANSACTION MADE USING ANY USER’S ID OR PASSWORD; AND (2) THE UNAUTHORIZED USE OF YOUR ONLINE ID AND PASSWORD FOR YOUR Core Vote ACCOUNT OR YOUR EMAIL ACCOUNT COULD CAUSE YOU TO INCUR LIABILITY TO BOTH Core Vote AND OTHER USERS. 
      </Typography>
      <Typography className={classes.text}>
        Further, we may, without notice to you, suspend or cancel your listing at any time even without receiving notice from you if we suspect, in our sole discretion, that your account with us or your email account is being used in an unauthorized or fraudulent manner. 
      </Typography>

      <Typography className={classes.title}>7.  Limitations on Communications and Use of Other Users’ Information; No Spam.</Typography>
      <Typography className={classes.text}>
        You agree that, with respect to other users' personal information that you obtain directly or indirectly from or through the Site or through any Site-related communication, transaction or software, we have granted to you a license to use such information only for: (i) Site-related communications that are not unsolicited commercial messages, (ii) using services offered through the Site, and (iii) inquiring about or otherwise facilitating a financial transaction between you and the other user related to the purpose of the Site (such as inquiring about or booking a property or charging a personal credit card). Any other purpose will require express permission from the user. You may not use any such information for any unlawful purpose or with any unlawful intent. 
      </Typography>
      <Typography className={classes.text}>
        In all cases, you must give users an opportunity to remove their information from your address book or database or other records and a chance to review what information you have collected about them. In addition, under no circumstances, except as defined in this provision, may you disclose personal information about another user to any third party without the consent of the other user. You agree that other users may use your personal information to communicate with you in accordance with this provision. Further, you agree that you will protect other users’ personal information with the same degree of care that you protect your own confidential information (using at minimum a reasonable standard of care), and you assume all liability for the misuse, loss or unauthorized transfer of such information.
      </Typography>
      <Typography className={classes.text}>
        We do not tolerate spam or unsolicited commercial electronic communications of any kind. Therefore, without limiting the foregoing, you are not licensed to add a Site user, even a user who has rented a short-term property from you or to you, to your mailing list (email or physical mail) without the user's express consent. You may not use any tool or service on the Site to send spam or unsolicited commercial electronic communications of any kind or in any other way that would violate these Terms. 
      </Typography>

      <Typography className={classes.title}>8. Responsibility for Property Listings, Reviews and Other User-contributed content; Participation in Interactive Forums.</Typography>
      <Typography className={classes.text}>
        We have no duty to pre-screen content posted on the Site by members, travelers or other users, whether directly contributed by the user or contributed by us or a third party on behalf of the user (including, without limitation, property listings, reviews of a rental property or a traveler, participation in an interactive community, forum or blog (each an “Interactive Forum”) or any other content provided by a user to the Site), (collectively, “user-contributed content”). We are not responsible for user-contributed content. “User-contributed content” also includes information that a user or any other person provided to a third party website or mobile application that is then provided to our Site by a tool we offer or any other exchange of user-contributed content we have authorized.
      </Typography>
      <Typography className={classes.text}>
        We reserve the right to decline to permit the posting on the Site of or to remove from the Site any user-contributed content that fails to meet our Content Guidelines, which are incorporated by reference into these Terms, any other guidelines posted on a Site or if it otherwise violates these Terms, each as determined in our discretion. We may also remove user-contributed content if it is brought to our attention, such as by notice given to us by a user or any third party that any part of these Terms, or any other requirements governing the posting of such content, have been apparently breached in respect of such content, as determined in our discretion. Finally, we reserve the right, but do not assume the obligation, to edit a member’s content or user-contributed content in a non-substantive manner solely to cause the content to comply with our Content Guidelines or formatting requirements or to provide services to members to create or improve listings (such as translation services), in accordance with information we have about the property listed. Users remain responsible for reviewing their user-contributed content to ensure it is accurate and not misleading.
      </Typography>
      <Typography className={classes.text}>At a minimum, user-contributed content must (as determined by us in our discretion):</Typography>
      <ul className={classes.text}>
        <li>not infringe anyone's rights, including but limited to copyright and rights of publicity and privacy, violate the law or otherwise be inappropriate</li>
        <li>not include personal information of another that can be used to identify or contact any person</li>
        <li>not include unsolicited promotional content, advertising, political campaigns, contests, raffles or solicitations</li>
        <li>be directly related to the Site, business service, product or forum where the content is submitted</li>
        <li>not be obscene, abusive, discriminatory or illegal</li>
        <li>not be false or misleading.</li>
      </ul>
      <Typography className={classes.text}>
        All property listings on the Site are the sole responsibility of the member (who may be the owner or a property manager or duly authorized agent of the owner) and we specifically disclaim any and all liability arising from the alleged accuracy of the listings, reviews or any alleged breaches of contract on a user's part. Members are solely responsible for keeping their property information up-to-date on the Site, including, but not limited to, any and all representations about any property, its amenities, location, price and its availability for a specific date or range of dates. We do not represent or warrant that any of the copy, content, traveler or property reviews, tenant book entries, property location, suitability, pricing or availability information published on the Site is accurate or up-to-date even in the case where travelers have searched for specific dates or types of properties.
      </Typography>
      <Typography className={classes.text}>
        We also may from time to time create new descriptions or otherwise change the location or geographic descriptions we use to identify properties in their listings and search results. Consequently, we may change the location or geographic description associated with any property listing at any time without notice. However, we assume no responsibility to verify property listing content or the accuracy of the location. Members are solely responsible for ensuring the accuracy of location, geographic and other content and location or geographic descriptions and agree to promptly correct (or contact us to correct) any inaccuracy and travelers are solely responsible for verifying the accuracy of such content and descriptions.
      </Typography>
      <Typography className={classes.text}>
        All other user-contributed content is the sole responsibility of the user who contributed such content, whether such user contributed the content directly or through a third-party website. Users are solely responsible for their user-contributed content and we specifically disclaim all liability for user-contributed content. The user represents and warrants that the user owns or otherwise controls and has all necessary legal rights to the user’s submission and the name or other identifier used in connection with such submission including, but not limited to, all the rights necessary to provide, post, upload, input or submit the user-contributed content. We reserve the right to request a proof of timeshare or permission, and to refuse to post user generated content without such proof or if such proof is, in our sole discretion, insufficient.
      </Typography>
      <Typography className={classes.text}>
        By submitting or authorizing user-contributed content, you grant to us and our affiliates a perpetual, worldwide, irrevocable, unrestricted, non-exclusive, royalty-free and fully paid-up license to use, copy, license, sublicense (through multiple tiers), adapt, distribute, display, publicly perform, reproduce, transmit, modify, edit and otherwise exploit the copy, the photographs and the likenesses (if any) of any of your user-contributed content, in connection with our business or the business of our affiliates. Notwithstanding the foregoing, following the termination or expiration of a property listing, we will not continue to display the user-contributed content that was displayed in such listing.
      </Typography>
      <Typography className={classes.text}>
        You further grant us and our affiliates the ability to register copyright in and protect the user-contributed content, including the images, copy, and content available via any member’s listing, from the unauthorized use of the user-contributed content by unaffiliated third parties who may, from time to time, attempt to pirate such information via electronic or other means. This includes, but is not limited to, the right to file suit to seek injunctive relief to protect such material on behalf of and in your name. You further agree to appear and assist us—at our expense and control—with protecting such copyrighted material from unauthorized redistribution.
      </Typography>
      <Typography className={classes.text}>
        You agree that we may sublicense all the rights granted to us under these Terms to one or more third parties we may contract with to display all or part of the member’s property listing or otherwise provide promotional or other services related to our business. In the event that you retain any rights of attribution, integrity or any other moral rights in any user-contributed content, you hereby waive your right to assert these or to require that any personally identifying information be used in connection with the user-contributed content or any derivative works thereof and affirm that you have no objection to the publication, use, modification, deletion or exploitation of the user-contributed content by us or our affiliates. 
      </Typography>

      <Typography className={classes.title}>9. Service Fee Payable by Travelers and choice of currency.</Typography>
      <Typography className={classes.text}>
        We charge a service fee payable by travelers who book a property on the Site via the Site checkout. The service fee covers the use of the Site, including such features as 24/7 user support, and is calculated as a variable percentage of the total reservation amount (which may or may not include additional fees, taxes and damage deposits). Depending on the laws of the jurisdiction of the traveler and/or member, VAT may be charged on top of the service fee. The exact service fee (and any VAT, if applicable) charged will be displayed to travelers at the time of booking. The service fee plus applicable VAT will be charged after both the traveler and member accept the reservation. The service fee will only be refunded in the event a traveler is entitled to a refund of the entire rental amount under the terms of the cancellation policy presented in the listing. Any taxes alleged to be owed by any taxing authority on the service fee are the responsibility of Core Vote and members have no responsibility for any such claimed tax liability. Members agree not to encourage or advise a traveler to avoid or circumvent the service fee charged by Core Vote.
      </Typography>
      <Typography className={classes.text}>
        Core.vote allows travelers to display prices on the Site in the currency of their choice and may also allow travelers to pay in the currency of their choice. Different currency choices are generally based on publicly available currency rates and are not guaranteed to be the best rate available. If your currency of choice is not supported for a booking, you’ll be charged in the owner’s selected currency. In any event, the currency and exact amount you will pay will be displayed clearly at check out. Our overall compensation may be affected by the currency you choose to pay in.  Note that if you pay in a currency different than the currency of your card, your bank or card provider may charge you foreign exchange, transaction, or other fees. 
      </Typography>

      <Typography className={classes.title}>10. Social Media or Third-Party Websites.</Typography>
      <Typography className={classes.text}>
        If the Site offers a tool or service that allows us to access or use any profile or other information about you that you have provided to Facebook or another third-party website (each a “Social Media Site”) and you decide to use such tool or service, you acknowledge and agree that:
      </Typography>
      <ul className={classes.text}>
        <li>The information or content that is a part of your Social Media Site profile, which you have designated as “public” (or a similar designation) (with such information or content and referred to herein as</li>
        <li>The Social Media Content will be considered user-generated content under these Terms and both you and we shall have the same rights and responsibilities as you that we have with respect to user-generated content under these Terms</li>
        <li>In the event that the Social Media Content was for any reason misclassified with a public or similar designation or is otherwise inaccurate or to which you do not agree with for any reason, you agree to work with the Social Media Site to make any changes or resolve any disputes and acknowledge that we will not be able to provide you with recourse</li>
        <li>The operation of your profile and account with and on the Social Media Site shall continue to be governed by the terms and conditions and privacy policy of such Social Media Site.</li>
      </ul>

      <Typography className={classes.title}>11. Translations and Maps.</Typography>
      <Typography className={classes.text}>
        If any user-contributed content created by members or users is translated for display on the Site or any site of any affiliate of Core Vote, we cannot guarantee the accuracy or quality of such translation and the user is solely responsible for the review, verification and accuracy of such translation. Maps provided on the Site that are provided by Google are subject to the Google Maps terms and conditions located at: <a href="http://www.google.com/intl/en_us/help/terms_maps.html" target="_blank" rel="noreferrer">http://www.google.com/intl/en_us/help/terms_maps.html</a>.
      </Typography>
      
      <Typography className={classes.title}>12. Notification of Infringement; DMCA Policy.</Typography>
      <Typography className={classes.text}>
        We respect the intellectual property rights of others, and Core Vote does not permit, condone or tolerate the posting of any content on the Site that infringes any person's copyright. Core Vote will terminate, in appropriate circumstances, a member or traveler who is the source of repeat infringement of copyright. Should you become aware of or suspect any copyright infringement on this Site, please refer to our procedures for Notification of Copyright Infringement (or DMCA Policy), which are incorporated by reference into these Terms. 
      </Typography>

      <Typography className={classes.subtitle}>13.  Unsolicited Ideas and Feedback.</Typography>
      <Typography className={classes.text}>
        From time to time, users submit to us ideas or suggestions pertaining to our business, such as ideas for new or improved products or technologies, website or tool enhancements, processes, materials, marketing plans or new product names. We are under no obligation to review or consider them. If you choose to submit any ideas, original creative artwork, suggestions or other works (“submissions”) in any form to us, then regardless of what you say, write or provide to us in connection with your submissions, the following terms shall apply. The sole purpose of this policy is to avoid potential misunderstandings or disputes in the event that any part of our business, such as our products, websites, technologies or marketing strategies, seems similar to any of your submissions. If you provide any submissions to us, you agree that: (i) your submission and its contents will automatically become the property of Core Vote, without any compensation to you; (ii) Core Vote may use or redistribute any such submission and its contents for any purpose and in any way; (iii) there is no obligation for Core Vote to review any submission; and (iv) there is no obligation to keep any submission confidential. 
      </Typography>
      <Typography className={classes.text}>
        We welcome your feedback regarding many areas of our business. If you want to send us your feedback, we simply request that you send it to us using the “feedback” tab located on the bottom right side of the homepage, search results pages, property pages, and the owner dashboard. Please provide only specific feedback on our Site and services. Keep in mind that we assume no obligation to keep any feedback you provide confidential and we reserve the right to use or disclose such information in any manner.
      </Typography>

      <Typography className={classes.title}>14. Software Available on the Site. </Typography>
      <Typography className={classes.text}>
        The Site is controlled and operated by Core Vote or an affiliate of Core Vote in the United States. Software available on the Site (the “Software”) is subject to United States export controls. No Software available on the Site or software available on any other site operated by Core Vote or an affiliate of Core Vote in the United States may be downloaded or otherwise exported or re-exported (i) into (or to a resident of) Cuba, Iraq, Libya, North Korea, Iran, Syria or any other country to which the United States has embargoed goods, or (ii) anyone on the United States Treasury Department’s list of Specially Designated Nationals or the United States Commerce Department’s Table of Deny Orders. By using the Site, you represent and warrant that you are not located in, under the control of, or a national or resident of any such country or on any such list.
      </Typography>
      <Typography className={classes.text}>
        All Software is the copyrighted work of Core Vote, an affiliate of Core Vote or an identified third party. Your use of such Software is governed by these Terms and the terms of any additional license agreement that accompanies or is included with such Software. If the Software is not accompanied by an additional license agreement, we hereby grant you a limited, personal and nontransferable license to use the Software for viewing and using this Site in accordance with these Terms and for no other purpose.
      </Typography>
      <Typography className={classes.text}>
        THE SOFTWARE IS WARRANTED, IF AT ALL, ONLY ACCORDING TO THE TERMS OF THE LICENSE AGREEMENT ACCOMPANYING SUCH SOFTWARE. COPYING OR REPRODUCING ANY SOFTWARE AVAILABLE ON THIS SITE IS EXPRESSLY PROHIBITED, EXCEPT AS SPECIFICALLY PROVIDED FOR IN A LICENSE AGREEMENT ACCOMPANYING SUCH SOFTWARE.
      </Typography>

      <Typography className={classes.subtitle}>15. Links to Third-Party Sites.</Typography>
      <Typography className={classes.text}>
        This Site may contain links and pointers to other Internet sites, resources and sponsors of the Site. Links to and from the Site to other third-party sites, maintained by third parties, do not constitute an endorsement by us of any third parties, the third-party sites or the contents thereof. We may also provide tools to allow interaction between the Site and a third-party site, such as a Social Media Site. We are not responsible in any way for such third-party sites or resources and your use of such sites and resources will not be governed by these Terms.
      </Typography>

      <Typography className={classes.title}>16. Limitation of Liability.</Typography>
      <Typography className={classes.subtitle}>
        IN NO EVENT WILL Core Vote, OR ITS PARENT COMPANY, SUBSIDIARIES, AFFILIATES, OFFICERS, DIRECTORS, CONSULTANTS, AGENTS AND/OR EMPLOYEES (COLLECTIVELY, THE “Core Vote GROUP”), OR ANY THIRD-PARTY PROVIDER OF A SERVICE OR TOOL OFFERED ON ANY SITE OF A MEMBER OF THE Core Vote GROUP (EACH A “THIRD-PARTY PROVIDER”), BE LIABLE FOR ANY LOST PROFITS OR ANY INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES ARISING OUT OF, BASED ON, OR RESULTING FROM (A) OUR SITE, (B) THESE TERMS, (C) ANY BREACH OF THESE TERMS BY YOU OR A THIRD PARTY, (D) USE OF THE SITE, TOOLS OR SERVICES WE PROVIDE, OR ANY THIRD PARTY PROVIDER PROVIDES, RELATED TO THE BUSINESS WE OPERATE ON THE SITE, BY YOU OR ANY THIRD PARTY (E) ANY USER-CONTRIBUTED CONTENT, (F) INTERACTION BETWEEN OUR SITE AND ANY THIRD PARTY SITE, INCLUDING WITHOUT LIMITATION A SOCIAL MEDIA SITE, FACILITATED BY A TOOL OR SERVICE ON OUR SITE AND/OR (G) ANY ACTUAL OR ATTEMPTED COMMUNICATION OR TRANSACTION, INCLUDING WITHOUT LIMITATION, ANY PAYMENT TRANSACTION (EVEN IF WE OR ANY THIRD PARTY PROVIDER RECEIVES A FEE IN CONNECTION THEREWITH) BETWEEN USERS, IN EACH CASE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THESE LIMITATIONS AND EXCLUSIONS APPLY WITHOUT REGARD TO WHETHER THE DAMAGES ARISE FROM (1) BREACH OF CONTRACT, (2) BREACH OF WARRANTY, (3) STRICT LIABILITY, (4) TORT, (5) NEGLIGENCE, OR (6) ANY OTHER CAUSE OF ACTION, TO THE MAXIMUM EXTENT SUCH EXCLUSION AND LIMITATIONS ARE NOT PROHIBITED BY APPLICABLE LAW.
      </Typography>
      <Typography className={classes.subtitle}>
        IF YOU ARE DISSATISFIED WITH THE SITE, YOU DO NOT AGREE WITH ANY PART OF THE TERMS, OR HAVE ANY OTHER DISPUTE OR CLAIM WITH OR AGAINST US, ANY THIRD PARTY PROVIDER OR ANY USER OF THE SITE WITH RESPECT TO THESE TERMS OR THE SITE, THEN YOUR SOLE AND EXCLUSIVE REMEDY AGAINST US IS TO DISCONTINUE USING THE SITE. IN ALL EVENTS, OUR LIABILITY, AND THE LIABILITY OF ANY MEMBER OF THE Core Vote GROUP, TO YOU OR ANY THIRD PARTY IN ANY CIRCUMSTANCE ARISING OUT OF OR IN CONNECTION WITH THE SITE IS LIMITED TO THE GREATER OF (A) THE AMOUNT OF FEES YOU PAY TO US IN THE TWELVE MONTHS PRIOR TO THE ACTION GIVING RISE TO LIABILITY OR (B) $100.00 IN THE AGGREGATE FOR ALL CLAIMS.  
      </Typography>

      <Typography className={classes.title}>17. Disclaimers.</Typography>
      <Typography className={classes.subtitle}>
        THE SITE, INCLUDING ALL CONTENT, SOFTWARE, FUNCTIONS, MATERIALS AND INFORMATION MADE AVAILABLE ON OR ACCESSED THROUGH THE SITE, IS PROVIDED “AS IS.“ TO THE FULLEST EXTENT PERMISSIBLE BY LAW, WE MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND WHATSOEVER FOR THE CONTENT ON THE SITE OR THE MATERIALS, INFORMATION AND FUNCTIONS MADE ACCESSIBLE BY THE SOFTWARE USED ON OR ACCESSED THROUGH THE SITE, FOR ANY PRODUCTS OR SERVICES OR HYPERTEXT LINKS TO THIRD PARTIES OR FOR ANY BREACH OF SECURITY ASSOCIATED WITH THE TRANSMISSION OF SENSITIVE INFORMATION THROUGH THE SITE OR ANY LINKED SITE, EVEN IF WE BECOME AWARE OF ANY SUCH BREACHES. FURTHER, WE EXPRESSLY DISCLAIM ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, WITHOUT LIMITATION, NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR ACCURACY. WE DO NOT WARRANT THAT THE FUNCTIONS CONTAINED ON THE SITE OR ANY MATERIALS OR CONTENT CONTAINED THEREIN WILL BE UNINTERRUPTED OR ERROR FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE OR THE SERVER THAT MAKES IT AVAILABLE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU ACKNOWLEDGE AND AGREE THAT ANY TRANSMISSION TO AND FROM THIS SITE IS NOT CONFIDENTIAL AND YOUR COMMUNICATIONS OR USER-CONTRIBUTED CONTENT MAY BE READ OR INTERCEPTED BY OTHERS. YOU FURTHER ACKNOWLEDGE AND AGREE THAT BY SUBMITTING COMMUNICATIONS OR USER-CONTRIBUTED CONTENT TO US AND BY POSTING INFORMATION ON THE SITE, INCLUDING PROPERTY LISTINGS, NO CONFIDENTIAL, FIDUCIARY, CONTRACTUALLY IMPLIED OR OTHER RELATIONSHIP IS CREATED BETWEEN YOU AND US OTHER THAN PURSUANT TO THESE TERMS.
      </Typography>
      <Typography className={classes.subtitle}>
        YOU ACKNOWLEDGE AND AGREE THAT YOU WILL NOT HOLD OR SEEK TO HOLD US OR ANY THIRD PARTY PROVIDER RESPONSIBLE FOR THE CONTENT PROVIDED BY ANY USER, INCLUDING, WITHOUT LIMITATION, ANY TRANSLATION THEREOF, AND YOU FURTHER ACKNOWLEDGE AND AGREE THAT WE ARE NOT A PARTY TO ANY RENTAL TRANSACTION OR OTHER TRANSACTION BETWEEN USERS OF THE SITE. WE HAVE NO CONTROL OVER AND DO NOT GUARANTEE (OTHER THAN PURSUANT TO ANY GUARANTEE THAT MAY BE OFFERED ON THE SITE) THE SAFETY OF ANY TRANSACTION, RENTAL PROPERTY OR THE TRUTH OR ACCURACY OF ANY LISTING OR OTHER CONTENT PROVIDED ON THE SITE. 
      </Typography>
      <Typography className={classes.subtitle}>
        YOU FURTHER ACKNOWLEDGE THAT BY DISPLAYING INFORMATION OR PROPERTY LISTINGS IN PARTICULAR DESTINATIONS, WE DO NOT REPRESENT OR WARRANT THAT TRAVEL TO SUCH DESTINATIONS IS WITHOUT RISK AND ARE NOT LIABLE FOR DAMAGES WITH RESPECT TO TRAVEL TO ANY DESTINATION. 
      </Typography>

      <Typography className={classes.title}>18.  Release; Indemnification. </Typography>
      <Typography className={classes.subtitle}>
        IN THE EVENT THAT YOU HAVE A DISPUTE WITH ONE OR MORE OTHER USERS OF THE SITE (INCLUDING, WITHOUT LIMITATION, ANY DISPUTE BETWEEN USERS REGARDING ANY TRANSACTION OR USER-CONTRIBUTED CONTENT) OR ANY THIRD PARTY PROVIDER OR ANY THIRD PARTY WEBSITE THAT MAY BE LINKED TO OR FROM OR OTHERWISE INTERACT WITH THE SITE, INCLUDING WITHOUT LIMITATION ANY SOCIAL MEDIA SITE, YOU HEREBY AGREE TO RELEASE, REMISE AND FOREVER DISCHARGE EACH MEMBER OF THE Core Vote GROUP, EACH OF THEIR RESPECTIVE AGENTS, DIRECTORS, OFFICERS, EMPLOYEES, AND ALL OTHER RELATED PERSONS OR ENTITIES FROM ANY AND ALL MANNER OF RIGHTS, CLAIMS, COMPLAINTS, DEMANDS, CAUSES OF ACTION, PROCEEDINGS, LIABILITIES, OBLIGATIONS, LEGAL FEES, COSTS, AND DISBURSEMENTS OF ANY NATURE WHATSOEVER, WHETHER KNOWN OR UNKNOWN, WHICH NOW OR HEREAFTER ARISE FROM, RELATE TO, OR ARE CONNECTED WITH SUCH DISPUTE AND/OR YOUR USE OF THE SITE.
      </Typography>
      <Typography className={classes.subtitle}>
        IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE SECTION 1542, WHICH SAYS: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH, IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.”  
      </Typography>
      <Typography className={classes.subtitle}>
        YOU HEREBY AGREE TO INDEMNIFY, DEFEND AND HOLD EACH MEMBER OF THE Core Vote GROUP (COLLECTIVELY, THE “INDEMNIFIED PARTIES“) HARMLESS FROM AND AGAINST ANY AND ALL LIABILITY AND COSTS INCURRED BY THE INDEMNIFIED PARTIES IN CONNECTION WITH ANY CLAIM ARISING OUT OF YOUR USE OF THE SITE OR OTHERWISE RELATING TO THE BUSINESS WE CONDUCT ON THE SITE (INCLUDING, WITHOUT LIMITATION, ANY POTENTIAL OR ACTUAL COMMUNICATION, TRANSACTION OR DISPUTE BETWEEN YOU AND ANY OTHER USER OR THIRD PARTY), ANY CONTENT POSTED BY YOU OR ON YOUR BEHALF OR POSTED BY OTHER USERS OF YOUR ACCOUNT TO THE SITE, ANY USE OF ANY TOOL OR SERVICE PROVIDED BY A THIRD PARTY PROVIDER, ANY USE OF A TOOL OR SERVICE OFFERED BY US THAT INTERACTS WITH A THIRD PARTY WEBSITE, INCLUDING WITHTOUT LIMITATION ANY SOCIAL MEDIA SITE OR ANY BREACH BY YOU OF THESE TERMS OR THE REPRESENTATIONS, WARRANTIES AND COVENANTS MADE BY YOU HEREIN, INCLUDING WITHOUT LIMITATION, ATTORNEYS' FEES AND COSTS. YOU SHALL COOPERATE AS FULLY AS REASONABLY REQUIRED IN THE DEFENSE OF ANY CLAIM. 
      </Typography>
      <Typography className={classes.subtitle}>
        WE RESERVE THE RIGHT, AT OUR OWN EXPENSE, TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF ANY MATTER OTHERWISE SUBJECT TO INDEMNIFICATION BY YOU AND YOU SHALL NOT IN ANY EVENT SETTLE ANY MATTER WITHOUT OUR WRITTEN CONSENT.  
      </Typography>

      <Typography className={classes.title}>19.  Disputes; Arbitration. </Typography>
      <Typography className={classes.text}>
        Core Vote’s right to amend these Terms, in whole or in part, at any time as set forth below in Section 22 does not apply to this “Disputes; Arbitration“ section. The version of this “Disputes; Arbitration” section in effect on the date you last accepted the Terms controls. 
      </Typography>
      <Typography className={classes.text}>
        Core Vote is committed to customer satisfaction, so if you have a problem or dispute, we will try to resolve your concerns. But if we are unsuccessful, you may pursue claims as explained in this section. You agree to give us an opportunity to resolve any disputes or claims relating in any way to the Site, any dealings with our customer experience agents, any services or products provided, any representations made by us, or our Privacy Policy (“Claims”) by contacting Core.vote Customer Support or 1-877-228-3145. If we are not able to resolve your Claims within 60 days, you may seek relief through arbitration or in small claims court, as set forth below.
      </Typography>
      <Typography className={classes.text}>
        <strong>Any and all Claims will be resolved by binding arbitration, rather than in court</strong>, except you may assert Claims on an individual basis in small claims court if they qualify. This includes any Claims you assert against us, our subsidiaries, users or any companies offering products or services through us (which are beneficiaries of this arbitration agreement). This also includes any Claims that arose before you accepted these Terms, regardless of whether prior versions of the Terms required arbitration.
      </Typography>
      <Typography className={classes.subtitle}>
        There is no judge or jury in arbitration, and court review of an arbitration award is limited. However, an arbitrator can award on an individual basis the same damages and relief as a court (including statutory damages, attorneys’ fees and costs), and must follow and enforce these Terms as a court would.
      </Typography>
      <Typography className={classes.text}>
        Arbitrations will be conducted by the American Arbitration Association (AAA) under its rules, including the AAA Arbitration Consumer Rules (together, the “AAA Rules”). Payment of all filing, administration and arbitrator fees will be governed by the AAA's rules, except as provided in this section. If your total Claims seek less than $10,000, we will reimburse you for filing fees you pay to the AAA and will pay arbitrator’s fees. You may choose to have an arbitration conducted by telephone, based on written submissions, or in person in the state where you live or at another mutually agreed upon location.
      </Typography>
      <Typography className={classes.text}>
        By agreeing to arbitration under the AAA Rules, the parties agree, among other things, that the arbitrator, and not any federal, state, or local court or agency, shall have the exclusive power to rule on any objections with respect to the existence, scope, or validity of the arbitration agreement or to the arbitrability of any claim or counterclaim 
      </Typography>
      <Typography className={classes.text}>
        Prior to beginning an arbitration proceeding, you must send a letter describing your Claims to “Core Vote Legal: Arbitration Claim Manager”. If we request arbitration against you, we will give you notice at the email address or street address you have provided. The AAA's rules and filing instructions are available at www.adr.org or by calling 1-800-778-7879. 
      </Typography>
      <Typography className={classes.text}>
        <strong>Any and all proceedings to resolve Claims will be conducted only on an individual basis and not in a class, consolidated or representative action.</strong> If for any reason a Claim proceeds in court rather than in arbitration <strong>we each waive any right to a jury trial.</strong> The Federal Arbitration Act and federal arbitration law apply to these Terms. An arbitration decision may be confirmed by any court with competent jurisdiction.
      </Typography>

      <Typography className={classes.title}>20. Additional Terms and Conditions Applicable to Online Booking.</Typography>
      <Typography className={classes.text}>
        In addition to being bound by the other terms set forth herein, users and members who use any tool provided by us or a third-party provider on the Site enabling users to book and/or pay for the rental of properties online on one or more of the Sites (collectively, the <strong>“Reservation Services”</strong>) are also bound by the following terms, which are in addition to any other terms applicable in connection with using the Site. In addition, if such Reservation Services include payment or other services provided by a third-party provider, such services are subject to the additional terms and conditions and privacy policies of such third-party providers.
      </Typography>
      <Typography className={classes.text}>
        We provide Reservation Services to manage inquiries, quotes, and rental agreements and to allow for payments to be made relating to the rental. Please review the following terms and the terms and conditions of any third-party provider carefully. If you do not agree to these or such third-party provider’s terms, you have no right to obtain information from or otherwise continue using our Reservation Services. Failure to use our Reservation Services in accordance with the following terms of use may subject you to severe civil and criminal penalties and other liability.
      </Typography>
      <Typography className={classes.text}>
        By utilizing a rental agreement as part of the Reservation Services or otherwise displaying terms relating to the rental as part of the online booking process (including such terms that we may require), the traveler and member each agree to the terms and conditions set forth in the rental agreement or other such terms displayed in the booking process (including without limitation the cancellation refund policy) effective as of the date that the user indicates acceptance of the booking or rental agreement, as applicable. You hereby acknowledge and agree that (i) you are fully responsible for such terms and conditions, (ii) any rental agreement used, whether a sample provided by the Site or a rental agreement copied and pasted in ReservationManager (or other online booking tool on the Site) by either party, is used solely at their own risk and expense, (iii) nothing contained in the Reservation Services, these Terms or any sample rental agreement is a substitute for the advice of an attorney and (iv) that you have been hereby advised to obtain local legal counsel to prepare, review and revise as necessary any rental agreements to ensure compliance with federal, state and local law and their particular circumstances, and to revise the rental agreement as necessary to accurately represent the property, rules, features, etc. 
      </Typography>
      <Typography className={classes.text}>
        There are some members, typically property managers, who use software provided by our affiliate, Core Vote Software, Inc., or a third party. Such software <strong>(“Other Reservation Services”)</strong> may be governed by terms provided by the third parties or members making such Other Reservation Services available. Users who use such Other Reservation Services are responsible for complying with such terms in addition to our Terms.
      </Typography>

      <Typography className={classes.title}>21. Responsibility for Property and Traveler Liability.</Typography>
      <Typography className={classes.text}>
        Users are solely responsible for obtaining insurance coverage sufficient to protect their properties and tenants or their trip, as applicable. Members agree that they have or will obtain the appropriate insurance coverage sufficient to cover the rental of the properties they list on the Site before the arrival of their first traveler and will maintain adequate insurance coverage through the departure date of any traveler they have obtained via our Site. Further, members agree to provide us with copies of relevant proof of coverage upon request.
      </Typography>
      <Typography className={classes.text}>
        By utilizing and/or purchasing Property Damage Protection provided by Core Vote partner CSA Travel Protection and Insurance Services, you agree to the terms and conditions under the Property Damage Protection plan, acknowledge that you understand that certain policy restrictions apply, and agree that Property Damage Protection may be included in the rental in lieu of or in addition to a security deposit. Full details of the Property Damage Protection coverage are contained in the Description of Coverage <a href="http://www.csatravelprotection.com/certpolicy.do?product=G-120HA" target="_blank" rel="noreferrer">http://www.csatravelprotection.com/certpolicy.do?product=G-120HA</a>. Members further acknowledge and agree that they will choose the plan level with the appropriate level of coverage needed for each property and that they will offer that same plan level to all users agreeing to rent the property. 
      </Typography>
      <Typography className={classes.text}>
        By utilizing and/or purchasing Cancellation Protection provided by Core Vote partner CSA Travel Protection and Insurance Services, you agree to the terms and conditions under the plan and acknowledge that certain policy restrictions apply. Full details of the Cancellation Protection coverage are contained in the Description of Coverage <a href="https://www.csatravelprotection.com/certpolicy.do?product=G-250HA" target="_blank" rel="noreferrer">https://www.csatravelprotection.com/certpolicy.do?product=G-250HA</a>.
      </Typography>

      <Typography className={classes.title}>22.  GENERAL</Typography>
      <Typography className={classes.text}>To Contact Us for any reason, users can visit help</Typography>
      <Typography className={classes.text}>
        These Terms are governed by the Federal Arbitration Act, 9 U.S.C. § 1 et seq. (“FAA”), AAA Rules, federal arbitration law, and for U.S. residents, the laws of the state in which you reside (as determined by the billing address you have provided us), without regard to conflict of laws principles. It is the intent of the parties that the FAA and AAA Rules shall preempt all state laws to the fullest extent permitted by law. 
      </Typography>
      <Typography className={classes.text}>
        Our relationship is that of independent contractors, and no agency, partnership, joint venture, employee-employer or franchiser-franchisee relations is intended or created by these Terms or your use of the Site. Except as explicitly stated otherwise, any notices to us shall be given by postal mail to: , Inc., Attn: Legal Department
      </Typography>
      <Typography className={classes.text}>
        When we need to send you notice, it will be sent to the email address you provide to the Site during the registration or booking or inquiry process or as later updated in your account (if applicable). Notice shall be deemed given upon receipt or 24 hours after an email is sent, unless the sending party is notified that the email address is invalid. Alternatively, we may give you notice by certified mail, postage prepaid and return receipt requested, to any physical address provided to us during the registration process or as later updated in your account (if applicable). Notice shall be deemed given three days after the date of mailing to a physical address and one day after mailing to an electronic address. 
      </Typography>
      <Typography className={classes.text}>
        We may change, suspend or discontinue any aspect of the Site at any time, including the availability of any Site features, database or content. We may also impose limits on certain features or services or restrict your access to parts or the entire Site without notice or liability. This version of the Terms became effective on the date set forth above and this version amends the version effective before such date. We reserve the right, in our sole discretion, to amend these Terms, in whole or in part, at any time. Notification of any amendment will be posted on the Site by the indication of the last amendment date at the top of these Terms and will be effective immediately. When members renew subscriptions, the terms in effect at the time of renewal will govern, provided that such terms may change as described above.
      </Typography>
      <Typography className={classes.text}>
        We also reserve the right, in our sole discretion and from time to time, to offer programs, products or services with unique terms and conditions that are separate from and may supersede or supplement in certain respects these Terms. In such cases, your use of the Site with respect to such special program is governed by these Terms together with the terms and conditions of such program, product or service. We reserve the right, but assume no obligation, to agree to different or conflicting terms and conditions with respect to any user. Any such terms and conditions will not be enforceable unless specifically agreed to by us.
      </Typography>
      <Typography className={classes.text}>
        Subscription rates and fees (including any commissions) charged for any listing that is not subscription based (such as pay-per-booking) are set at the time of a user or member’s purchase of the subscription or renewal or sign up for the non-subscription-based listing, as applicable. Such rates and fees are subject to change without notice or approval. For subscription listings, the rates in effect at the time of the member’s next subscription renewal, new listing or a member’s upgrade or any other additional or new order of any product or service will govern for such renewal or other order. The fees and commissions applicable to pay-per-booking listings offered on one or more Sites will be displayed under the “List Your Property” tab when such product is generally made available on a Site or shall be otherwise set forth in a communication between us and the member.
      </Typography>
      <Typography className={classes.text}>
        The types of products and services (including the features, terms and operation thereof) offered at the time of a member’s subscription or sign up for a non-subscription-based listing are subject to the descriptions displayed at the time of use and/or purchase and are subject to change without notice or approval. We further reserve the right to offer additional products, services or features for purchase at any time. 
      </Typography>
      <Typography className={classes.text}>
        We do not separately file the Terms entered into by each user of the Site. Please make a copy of these Terms for your records by printing and/or saving a downloaded copy of the Terms on your personal computer. We may immediately terminate any user’s access to or use of the Site due to such user’s breach of these Terms or any other unauthorized use of the Site. However, we do not guarantee that we will take action against all breaches of these Terms. Our failure to take immediate action with respect to a breach by you or others does not waive our right to act with respect to such breach or any other breach. 
      </Typography>
      <Typography className={classes.text}>
        These Terms constitute the entire agreement between us and you with respect to the matters set forth herein, and supersede any prior agreement between us and you with respect to your use of the Site. Headings in these Terms are for reference only and do not limit the scope or extent of such section. In the event of any conflict between these Terms and any other terms and conditions applicable to a product, tool or service offered on our Site, the Terms herein shall prevail. If any portion of these Terms is found to be invalid or unenforceable by any court of competent jurisdiction, the other provisions of these Terms shall remain in full force and effect. Further, any provision of these Terms held invalid or unenforceable only in part or degree will remain in full force and effect to the extent not held invalid or unenforceable.
      </Typography>
      <Typography className={classes.text}>
        We may assign these Terms in our sole discretion. Users must obtain our prior written consent to assign these Terms, which may be granted or withheld by us in our sole discretion. 
      </Typography>
      <Typography className={classes.text}>
        Core Vote is committed to an inclusive marketplace built on a foundation of trust and respect. Our global platform is open to travelers, owners, and property-managers of every race, color, religion, national origin, ancestry, sex, gender identity, sexual orientation, and marital status. We expect all users to demonstrate respect and tolerance in all interactions with other users—both online and off-line—and we reserve the right to remove anyone from our marketplace who fails to abide by these principles.
      </Typography>

      <Typography className={classes.title}>ADDITIONAL TERMS AND CONDITIONS APPLICABLE TO MEMBERS</Typography>
      <Typography className={classes.text}>
        In addition to being bound by the terms set forth above, members who advertise on the Site are also bound by the following terms, which are in addition to any other terms agreed to in connection with purchasing or renewing a listing.
      </Typography>

      <Typography className={classes.title}>23.  Member Eligibility; Accuracy of Information; Listing Practice Requirements.</Typography>
      <Typography className={classes.text}>
        Our services may only be used by members who can form legally binding contracts under applicable law. If you are registering as a business entity, you represent that you have the authority to bind the entity to these Terms. Each member represents and covenants that all information submitted to us and to the Site during such member’s registration with the Site shall be true and correct. Each member further agrees to promptly provide notice to the Site by contacting us as provided above under “Contact Us” regarding any updates to any such contact information previously submitted by such member to the Site. 
      </Typography>
      <Typography className={classes.text}>
        In order to list a property on a Site, members must comply with Core Vote’s Marketplace Standards. These Standards outline certain requirements including but not limited to:
      </Typography>
      <ul className={classes.text}>
        <li>Members must maintain an accurate reservation calendar on their listing(s)</li>
        <li>Members must use commercially reasonable efforts to respond to all booking requests from travelers within 24 hours of receipt of a request for booking</li>
        <li>Members must further agree to take commercially reasonable efforts to cause all traveler payments to be processed within 24 hours of authorization by the traveler for such payment</li>
        <li>Members must respond to and accept a material number of inquiries and booking requests received in the interest of the traveler experience</li>
        <li>Members are prohibited from cancelling a material number of accepted bookings.</li>
      </ul>

      <Typography className={classes.title}>24. Additional Member Representations. </Typography>
      <Typography className={classes.text}>
        Each member represents and covenants that: (i) it owns and/or has all necessary rights and authority to offer for rent and to rent the property listed by such member; (ii) it will not wrongfully withhold a rental deposit in breach of the underlying rental agreement; (iii) it will accurately describe the subject rental property, will not fail to disclose a material defect in, or material information about, a rental property and will upon request, or otherwise from time to time, review the property listing content and location or geographic description to ensure it is accurate and not misleading ; (iv) it will not wrongfully deny access to the listed property; and (v) it will not fail to provide a refund when due in accordance with the applicable cancellation policy or underlying rental agreement. In addition, if Core Vote, through one of its guarantee or warranty programs and in its sole discretion, compensates a traveler for a loss caused by acts or omissions attributable to a member, Core Vote reserves the right to pursue the member for the amount paid or contributed towards the loss. 
      </Typography>
      <Typography className={classes.text}>
        Upon our request, each member agrees to promptly provide to us such proof of personal identification, proof that the condition, location, or amenities associated with the property are accurately described in the listing, proof of timeshare of the property listed on the Site, and/or proof of authority to list the property. If you are a tenant who is listing a home, condominium, or apartment, please refer to your rental contract or lease, or contact your landlord, prior to listing the property to determine whether your lease or contract contains restrictions that would limit your ability to list your room, home, condominium or apartment. Listing your home may be a violation of your lease or contract and could result in legal action against you by your landlord, including possible eviction. 
      </Typography>

      <Typography className={classes.title}>25. Appearance in Search Results.</Typography>
      <Typography className={classes.text}>
        We cannot guarantee that your listing will appear in any specific order in search results on the Site. Search order will fluctuate based on a variety of factors such as search parameters, subscription level purchased, listing quality, how frequently a calendar is updated, traveler preferences, member response time, reservation history, online booking capability, compliance with posted listing practice requirements, and other factors that we may deem important to the user experience from time to time (“Best Match”). Listing appearance or order in any particular search result may also vary depending on the search criteria used by the particular traveler. We reserve the right to apply various search algorithms or to use methods to optimize Best Match results for particular travelers’ experiences and the overall marketplace. Listings placed on a non-subscription basis, such as pay-per-booking, may not always appear in search results. Listings distributed on third-party sites are not guaranteed to display on such third-party site in any particular order or at all. Search results and order may appear different on Core Vote’s mobile application than they appear on the Site. To optimize the search experience for both members and travelers and improve the Best Match process, Core Vote retains the right to run occasional tests that will be limited in duration but may alter how we display listings and search results.
      </Typography>

      <Typography className={classes.title}>26. Content, Layout and Copy.</Typography>
      <Typography className={classes.text}>
        All content and copy edits submitted by members are subject to review and approval by us in our sole discretion. We reserve the right to refuse to publish any content that we determine in our sole discretion does not meet these Terms or is otherwise unacceptable to us. However, we assume no duty to review content and we shall not have any liability for any loss or damage resulting from the design or positioning of the copy, properties, content and/or photographs or any change made to any content, photograph or copy submitted by any member. All content must meet these Terms and our Content Guidelines. We reserve the right to edit content submitted to the Site in a non-substantive manner solely to ensure that the content complies with our Content Guidelines or formatting requirements. Members are responsible for reviewing and ensuring that any content displayed on the Site appears as the member intended.
      </Typography>

      <Typography className={classes.title}>27. Photographs.</Typography>
      <Typography className={classes.text}>
        Photographs should depict the vacation rental as the main subject of the photograph and may not include children or adults if you do not have their legal consent or any information or images that would violate the privacy rights, intellectual property rights (including but not limited to copyright rights) or any other rights of a third party. We reserve the right to not display or to remove any photographs that we determine, in our sole discretion, do not meet these Terms or are otherwise unacceptable to us.
      </Typography>
      <Typography className={classes.text}>
        By submitting a photograph to us, the member represents and warrants that (a) it holds all intellectual property rights (including but not limited to all copyright rights), (b) that any people in the photograph have given permission for their likeness to be displayed in an online advertisement on the Site, (c) that the photograph accurately and fairly represents the subject of the photograph and has not been altered in any manner that would mislead a viewer of that photograph, and (d) that it will indemnify and hold harmless the Site and any member of the Core Vote Group from any cause of action arising from any misrepresentation with respect to any and all photographs so submitted or in the event of claims that the use of a photograph violates another party’s copyright.
      </Typography>
      <Typography className={classes.text}>
        It is the member’s responsibility to obtain any and all permissions required to use, post and grant the foregoing rights in all photographic and other material used in its listings. The member warrants that it is the owner of the copyright in such material or is authorized by the owner thereof to grant to us the rights therein contained and agrees to provide any proof of such rights to us that we may request. Each member further agrees that we may reproduce in whole or in part any photographic material supplied by such member in the promotion of either such member’s property or the Site. 
      </Typography>

      <Typography className={classes.title}>28.  Uses of Our Trademarks or Logos.</Typography>
      <Typography className={classes.text}>
        There are limited ways in which a member may use our trademarks or logos in connection with a listing without specific prior written authorization. The following are general guidelines. It is usually permissible for you to refer to Core Vote or the name of one of our affiliate websites on which you list your property in a descriptive manner in your listing on the Site or in other permissible communications. For example, you might say “Check out my vacation rental on Core Vote,“ or “I list properties on Core Vote.“ However, you may not refer to Core Vote or any of our affiliates in any way that might lead someone to believe that your company, property, listing or site is sponsored by, affiliated with, or endorsed by Core Vote or one of our affiliates. For example, you may not say “Core Vote sponsors my vacation rental,” or describe your property as “Core Vote’s best vacation rental.” You may not use the Core Vote name or one of our affiliates’ names on any other website that lists vacation rentals without our prior written authorization.
      </Typography>
      <Typography className={classes.text}>
        The Core Vote name and logo and those of the Core Vote Group and our affiliates are trademarks or registered trademarks in the United States and other jurisdictions around the world. We generally do not permit the use of our names and logos, other than as described above or with our prior written authorization. If you want permission to use our name and/or logo in any other manner, including, without limitation, on any website, business card, signage, t-shirt, etc., or if you have other questions, you may visit help.
      </Typography>

      <Typography className={classes.title}>29. Hypertext Links.</Typography>
      <Typography className={classes.text}>
        We reserve the right to refuse hypertext links to, or addresses of, other web sites from members' pages, and to remove links or web addresses without notice at our sole discretion. Further, we reserve the right to charge for hypertext links at any time.
      </Typography>

      <Typography className={classes.title}>30.  Substitution of Properties; Advertising More Than One Property; Property Managers.</Typography>
      <Typography className={classes.text}>
        Each listing must relate to an individual and uniquely identified property, unless you purchased a listing package that expressly allows for substitution of properties. This means that:
      </Typography>
      <ul className={classes.text}>
        <li>The property in a listing may not be substituted for another property without our consent. We may approve a request in our discretion if the property manager's contract for the property was terminated and the member provides sufficient proof, as requested by us, and completes any additional forms we may request. The type and term of the listing for any substituted property shall be the same as the type and term of the originally-listed property (i.e., the term will not be extended past the original term). If a member submits changes to an existing listing that, if approved, would substantially alter the listing to make it that of another property, then we have the right to terminate the listing and may choose, in our sole discretion, to retain any fees associated previously existing listing as compensation for the violation of this condition.</li>
        <li>The listing cannot be a mere example of properties in a given area. Only one property can appear on each listing, unless it is a property with multiple rental units and additional listings are purchased. We reserve the right to amend the copy or remove any listing when more than one property is described in such listing, and may choose, in our sole discretion to retain any fees associated such non-conforming listing as compensation for the violation of this condition.</li>
      </ul>

      <Typography className={classes.title}>31.  Payment Method Requirements; Bank and Credit Card Fees.</Typography>
      <Typography className={classes.text}>
        No member may request any traveler to mail cash, or utilize any instant-cash wire transfer service such as Western Union or MoneyGram in payment for all or part of a property rental transaction. Any violation of this term or any other unacceptable payment methods that may be posted on the Site may result in the immediate removal of the non-conforming listing from the Site without notice to the member and without refund. From time to time, we may become aware of users attempting to conduct a transaction that would involve an unauthorized payment method or a fraudulent payment method. Ideally, we hope to be able to assist users in avoiding such transactions, but we assume no liability or responsibility to do so or to inform users of any such actual or suspected activity.
      </Typography>
      <Typography className={classes.text}>
        Users’ bank or credit card companies may impose certain fees or charges, including, but not limited to, foreign transaction fees, related to any rental of a property. It is the user’s obligation to review any agreement with its bank or credit card company concerning any such fees.
      </Typography>

      <Typography className={classes.title}>32. Subscription Payments; Automatic Renewal of Subscription Payments.</Typography>
      <Typography className={classes.text}>
        Payment for subscription listings must be made to us in U.S. Dollars paid either by major credit or debit card, PayPal, or a check drawn on a U.S. bank. For any subscription paid for by credit card or PayPal, such subscription shall automatically renew at the expiration of the then-current term for an additional term of the same duration (as the previous term) and at the then-current non-promotional subscription rate. If such subscription was purchased by check or another form of payment other than by credit card or PayPal (if such other payment form was permitted), such subscription shall not be automatically renewed. The automatic renewal feature allows your service to remain uninterrupted at the expiration of your then-current term. If you wish to turn off auto-renewal, you must log on to your account and manually turn off auto-renewal in your owner dashboard at least five (5) days prior to expiration of the then-current term. Upon any such turning off of auto-renewal, your subscription will remain active through the expiration of your then-current subscription term; however your subscription will not be automatically renewed upon the expiration of your then-current term. If your subscription does not auto-renew or expires at the end of your then-current subscription term and you desire to renew your subscription, you will be required to pay the then-current non-promotional subscription rate to renew your subscription or to activate a new subscription.
      </Typography>
      <Typography className={classes.text}>
        If you do not turn off auto-renewal and you continue to use our subscription service, you re-affirm and authorize us to charge your form of payment at the end of each subscription term for an additional term of the same duration as the initial term and at the then-current, non-promotional subscription rate for the same product or service. 
      </Typography>
      <Typography className={classes.text}>
        If the product or service that you last purchased has changed in any way or is no longer offered, you agree and authorize us to charge your form of payment at the renewal of your subscription term for a product or service that is the most similar, as determined by us, to the product or service that you previously purchased, even if the price of such product or service is not the same as the prior product or service that you purchased. You agree to be responsible for any such charges, and we reserve the right to obtain payment directly from you if necessary. If you wish to change your form of payment to be charged or if your form of payment information otherwise changes, see help. for information on updating the payment information in your owner dashboard, as applicable or to provide the new or different form of payment information.
      </Typography>
      <Typography className={classes.text}>
        If a Site enables you to list your property on a basis other than by subscription, you agree to pay us compensation as described to you in the registration process, which compensation may be changed by us from time to time without notice by us displaying the compensation on the Site on which you registered for the listing. The registration process and additional notices you may receive from us may also provide additional terms and conditions for such listings.
      </Typography>

      <Typography className={classes.title}>33. Subscription Term and Refund Requests.</Typography>
      <Typography className={classes.text}>
        All subscription listings are sold to run the full term that is chosen by the member. The term starts on the date that the member submits the full or initial (as applicable) payment and expires one year thereafter. For example, for an annual subscription term, if the member submits payment for the subscription on July 1st, the subscription would expire on June 30 of the following year. If you renew your subscription listing, or if your subscription listing automatically renews, your listing will remain online for the new subscription period without refund.
      </Typography>
      <Typography className={classes.text}>
        Generally, no refunds are available unless a member qualifies for a refund under any special program we may have in effect. If you believe you qualify for a refund, you may contact customer support by sending your request to the address listed under “Contact Us” above and include your listing number, and your reason for requesting a refund. We will then determine, in our sole discretion, whether any refund is due. If you sell your property and no longer wish for the listing to remain online, please contact us and we can remove the listing; however, no refund will be owed. 
      </Typography>
      
      <Typography className={classes.title}>34. Additional Terms Applicable to Pay-Per-Booking Listings.</Typography>
      <Typography className={classes.text}>
        For all pay-per-booking listings, the terms and conditions in effect at the time a booking is accepted will govern and supersede any prior agreement between us and you with respect to your use of the Site.
      </Typography>
      <Typography className={classes.text}>These Terms constitute the entire agreement between us and you with respect to the matters set forth herein</Typography>
      <Typography className={classes.text}>
        A description of the features and applicable fees that will apply to pay-per-booking listings will be displayed under the “List Your Property” tab of the Site offering such product, when made generally available. Pay-per-booking listings are subject to the additional terms, conditions and requirements set forth during the registration for such listing, including those of our third party providers. Online booking and payments is required for all pay-per-booking listings. Online payments provided by third-party providers are subject to the terms and conditions and privacy policies of such providers.
      </Typography>
      <Typography className={classes.text}>
        Pay-per-booking listings may be converted to subscription listings at any time; however any bookings already made prior to conversion shall remain subject to applicable pay-per-booking fees. Cancellation policies are required for all pay-per-booking listings, and requirements for such cancellation policies shall be displayed through the “List Your Property” tab of the Site offering the pay-per-booking listing. 
      </Typography>

      <Typography className={classes.title}>35. Distribution of Listings to Third Party Websites.</Typography>
      <Typography className={classes.text}>
        To enable members to obtain broader distribution of their properties, we may provide your listing information and content, or otherwise provide for the distribution of your listing on a third party website. Additional terms and conditions may apply to such distributions, as we may notify you of via your owner dashboard or email.
      </Typography>

      <Typography className={classes.title}>36. Termination of Listings; Other Remedies.</Typography>
      <Typography className={classes.text}>
        If, in our sole discretion, any member submits unsuitable material to our Site or into our database, is not abiding by local rental regulations and is creating a nuisance in its community, misuses the Site or our online system or is in material breach of these Terms, we reserve the right to limit the member’s use of the Site, impact the member’s listing(s) search position, and/or terminate such member’s listing immediately without refund. In addition, if we become aware of or receive a complaint or a series of complaints from any user or other third party regarding a member’s listing or rental practices that, in our sole discretion, warrants the immediate removal of such member’s listing from the Site (for example, and without limitation, if a member double-books a property for multiple travelers on the same date, or engages in any practice that, in our sole discretion, would be considered deceptive, unfair or improper within the vacation rental industry or in an online marketplace for vacation rentals, if we determine or suspect that the member’s payment-related practices or procedures are not secure, legal or otherwise proper, or if we receive a complaint that any listing’s content infringes on the rights of a third party), then we may immediately terminate such member’s listing(s) or subscription(s) without notice to the member and without refund. We assume no duty to investigate complaints. Finally, if any member is abusive or offensive to any employee or representative of the Core Vote Group, we reserve the right to terminate such member’s listing(s) or subscription(s) immediately without refund.
      </Typography>
      <Typography className={classes.text}>
        In addition to reserving the right to terminate any listing, Core Vote reserves all rights to respond to any violation of the Terms or misuse of the Site by, including, but not limited to, hiding a listing from the search results, marking a member as “out of office,” and removing or changing information that may be false or misleading.
      </Typography>
      <Typography className={classes.text}>
        Additionally, you agree to defend and indemnify Core Vote, its affiliated companies, and any of their officers, directors, employees and agents from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting fees, brought by third parties as a result of:
      </Typography>
      <ul className={classes.text}>
        <li>your breach of these Terms or the documents referenced herein</li>
        <li>your violation of any law or the rights of a third party</li>
        <li>your use of this Site</li>
      </ul>
    </div>
  )
}

export default TermsConditions;

const useStyles = makeStyles({
  page: {
    padding: '16px',
    '@media (min-width: 600px)': {
      padding: '32px',
    },
    '@media (min-width: 1240px)': {
      padding: '40px 0',
    },
  },
  header: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '-0.35px',
    '@media (min-width: 600px)': {
      fontSize: '24px',
      lineHeight: '133.4%',
      letterSpacing: '-0.5px',
    },
    '@media (min-width: 1240px)': {
      fontSize: '40px',
      lineHeight: '116.7%',
    },
  },
  title: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '-0.35px',
    paddingTop: '30px',
    '@media (min-width: 1240px)': {
      fontSize: '20px',
      lineHeight: '160%',
    },
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '-0.43px',
    color: 'rgba(0, 0, 0, 0.6)',
    paddingTop: '20px',
    '@media (min-width: 600px)': {
      fontSize: '16px',
      lineHeight: '150%',
      letterSpacing: '-0.35px',
    },
  },
  text: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '-0.43px',
    paddingTop: '10px',
    '@media (min-width: 600px)': {
      fontSize: '16px',
      lineHeight: '150%',
      letterSpacing: '-0.35px',
    },
  }
});
