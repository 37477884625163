import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import useDialog from 'hooks/useDialog';
// Types
import UserRoles from 'types/UserRoles';
// Async
import AuthAsync from 'store/auth/authAsync';
// Selectors
import { selectCurrentUser } from 'store/users/usersSelectors';
import { selectIsAuthenticated } from 'store/auth/authSelectors';
import { selectSubscriptionModuleEnabled } from 'store/accounts/accountsSelectors';
// Mui
import {
  Button, Divider, IconButton, Paper,
  TextField, Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
// Components
import RecoveryPasswordRequestForm from './RecoveryPasswordRequest.form';
// Validation
import { isFieldRequired, isEmailValid } from '../utilities/Validation';

type Props = {
  onClose: () => void;
  openSignUp?: () => void;
  requestQuote?: boolean;
}

interface IFormData {
  email: string;
  password: string;
}

const SignInForm:React.FC<Props> = ({ onClose, openSignUp, requestQuote }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const subscriptionModuleEnabled = useAppSelector(selectSubscriptionModuleEnabled);
  const currentUser = useAppSelector(selectCurrentUser);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { control, handleSubmit, formState: { errors } } = useForm<IFormData>({
    mode: "onChange",
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const onSubmit = handleSubmit((data:IFormData) => {
    setIsLoading(true);
    dispatch(AuthAsync.signIn(data)).unwrap()
      .then(() => {
        if (requestQuote) onClose();
      })
      .finally(() => setIsLoading(false));
  });

  useEffect(() => {
    if (!currentUser) return;
    if (!requestQuote && isAuthenticated) {
      if (currentUser.activeRole === UserRoles.Owner) { 
        navigate(`${subscriptionModuleEnabled ? '/benefits' : '/my-timeshares'}`);
        onClose();
      } else {
        navigate('/reservations')
        onClose();
      }
    };
    // eslint-disable-next-line
  }, [currentUser, isAuthenticated]);

  const { Dialog, openDialog, closeDialog } = useDialog();

  return (
    <React.Fragment>
      <Dialog maxWidth="sm">
        <RecoveryPasswordRequestForm onClose={closeDialog} />
      </Dialog>
      <Paper elevation={6} variant="elevation" sx={{p:3}}>
        <IconButton onClick={onClose} sx={{ position: 'absolute', top: '16px', right: '16px' }}>
          <CloseOutlinedIcon />
        </IconButton>
        <Typography
          display="block"
          variant="h5"
          textAlign="center"
          sx={{ pt: 2, pb: 4 }}
        >Sign In</Typography>

        <form onSubmit={onSubmit} noValidate style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          {/* E-mail */}
          <Controller
            control={control} name="email"
            rules={{ required: isFieldRequired, pattern: isEmailValid }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                autoComplete="email"
                label="E-mail" type="email"
                error={Boolean(errors.email)}
                helperText={errors.email ? errors.email.message : ''}
                required
              />
            )}
          />
          {/* Password */}
          <Controller
            control={control} name="password"
            rules={{ required: isFieldRequired }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Password" type="password" autoComplete="current-password"
                error={Boolean(errors.password)}
                helperText={errors.password ? errors.password.message : ''}
                required
              />
            )}
          />
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            loading={isLoading}
            fullWidth
            sx={{ p: 1 }}
          >
            Sign In
          </LoadingButton>
          <Button
            onClick={openDialog}
          >
            Forgot password?
          </Button>
          {openSignUp && (
            <React.Fragment>
              <Divider />
              <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                Don’t have an account?
                <Button onClick={openSignUp}>
                  Sign up
                </Button>
              </Typography>
            </React.Fragment>
          )}
        </form>
      </Paper>
    </React.Fragment>
  );
}

export default SignInForm;
