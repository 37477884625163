import React from 'react';
// components
import Title from 'components/Title';
// mui
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
// Utilities
import { getContent } from 'utilities/Utilities';

const AboutPage:React.FC = () => {
  const classes = useStyles();

  const { block1, block2, block3, block4 } = getContent('aboutPage');

  return (
    <Box>
      <Box className={[classes.bigBlock, 'container'].join(' ')}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography className={classes.title}>{block1.title}</Typography>
          <Typography className={classes.text} sx={{ pt: { xs: 1, sm: 2, lg: 3 } }}>
            {block1.text}
          </Typography>
        </Box>
        <img src={block1.image} alt="" className={classes.img} />
      </Box>
      <Box sx={{  backgroundColor: 'rgba(245, 246, 247, 1)' }}>
        <Box className={[classes.smallBlock, 'container'].join(' ')}>
          <img src={block2.image} alt="" className={classes.icon} />
          <Title>{block2.title}</Title>
          <Typography className={classes.text} sx={{ textAlign: 'center' }}>
            {block2.text}
          </Typography>
        </Box>
      </Box>
      <Box className={[classes.block, 'container'].join(' ')}>
        <img src={block3.image} alt="" className={classes.img} />
        <Box sx={{ flexGrow: 1, order: { xs: -1, md: 0 } }}>
          <Title>{block3.title}</Title>
          <Typography className={classes.text} sx={{ pt: { xs: 1, sm: 2, lg: 3 } }}>
            {block3.text}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ backgroundColor: 'rgba(245, 246, 247, 1)' }}>
        <Box className={[classes.block, 'container'].join(' ')}>
          <Box sx={{ flexGrow: 1 }}>
            <Title>{block4.title}</Title>
            <Typography className={classes.text} sx={{ pt: { xs: 1, sm: 2, lg: 3 } }}>
              {block4.text}
            </Typography>
          </Box>
          <img src={block4.image} alt="" className={classes.img} />
        </Box>
      </Box>
    </Box>
  );
}

export default AboutPage;

const useStyles = makeStyles({
  bigBlock: {
    display: 'flex',
    gap: '24px',
    padding: '16px', // { xs: '32px', md: '80px 32px' },
    flexDirection: 'column', // { xs: 'column', md: 'row' }, 
    '@media (min-width: 600px)': {
      padding: '32px',
      gap: '48px',
    },
    '@media (min-width: 1240px)': {
      flexDirection: 'row',
      padding: '80px 0',
    }
  },
  block: {
    display: 'flex',
    gap: '16px',
    padding: '16px',
    flexDirection: 'column',
    '@media (min-width: 600px)': {
      gap: '32px',
      padding: '32px',
    },
    '@media (min-width: 1240px)': {
      gap: '48px',
      flexDirection: 'row',
      padding: '64px 0',
    }
  },
  smallBlock: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    '@media (min-width: 600px)': {
      gap: '16px',
      padding: '32px',
    },
    '@media (min-width: 1240px)': {
      gap: '24px',
      padding: '64px 0',
    }
  },
  title: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '120%',
    letterSpacing: '-1px',
    '@media (min-width: 600px)': {
      fontSize: '40px',
    },
    '@media (min-width: 1240px)': {
      fontSize: '60px',
    }
  },
  text: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '-0.35px',
    color: 'rgba(0, 0, 0, 0.6)',
    '@media (min-width: 600px)': {
      fontSize: '16px',
    },
  },
  img: {
    width: '560px',
    maxWidth: '100%',
    height: '400px',
    objectFit: 'cover',
    borderRadius: '8px',
  },
  icon: {
    width: '64px',
    height: '64px',
    '@media (min-width: 600px)': {
      width: '96px',
      height: '96px',
    },
    '@media (min-width: 1240px)': {
      width: '112px',
      height: '112px',
    }
  },
});
