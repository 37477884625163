import { useCallback, useEffect, useState, Fragment, ChangeEvent, FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import useDialog from 'hooks/useDialog';
// Async
import TimesharesAsync from 'store/timeshares/timesharesAsync';
import PropertiesAsync from 'store/properties/propertiesAsync';
// Components
import PropertiesFormDialog from 'components/PropertiesForm.dialog';
// Types
import ViewTypes from 'types/ViewTypes';
import TimeshareStatus from 'types/TimeshareStatus';
// Models
import ITimeshare from 'models/Timeshare';
import IProperty from 'models/Property';
// Actions
import { TimesharesActions } from 'store/timeshares/timesharesSlice';
import { UiActions } from 'store/ui/uiSlice';
import { PropertiesActions } from 'store/properties/propertiesSlice';
// Selectors
import { selectFormData } from 'store/timeshares/timesharesSelectors';
import { selectAllProperties } from 'store/properties/propertiesSelectors';
// Mui
import {
  Button, DialogActions, DialogContent,
  DialogTitle, TextField, Autocomplete,
  Grid, FormControl, InputLabel, Select,
  MenuItem, Box, debounce, FormHelperText,
  IconButton, InputAdornment
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import { Add as AddIcon } from '@mui/icons-material';
// Components
import { Input } from 'components/Controls';
// Utilities
import { isFieldRequired, isValidDate } from 'utilities/Validation';
import { getContent } from "utilities/Utilities";

interface IFormData {
  start: any,
  end: any,
  view: string,
  bedrooms: string | number,
  bathrooms: string | number,
  netPrice: string | number,
  ownerNotes: string,
  status: TimeshareStatus | null,
}

type Props = {
  onClose: () => void;
  timeshare?: ITimeshare | null;
}

const MyTimesharesForm:FC<Props> = ({ onClose, timeshare }) => {
  const timeshareId:number | null = timeshare?.id || null;
  const dispatch = useAppDispatch();

  const properties = useAppSelector(selectAllProperties) || [];
  const formData = useAppSelector(selectFormData);

  const [selectedProperty, setSelectedProperty] = useState(formData.property);
  const [selectedPropertyError, setSelectedPropertyError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingProperties, setIsLoadingProperties] = useState<boolean>(false);

  const handleSelectedProperty = (property: IProperty | null) => {
    setSelectedProperty(property);
    setSelectedPropertyError(property ? false : true);
  }

  useEffect(() => {
    setSelectedProperty(formData.property);
  }, [formData]);

  const { control, handleSubmit, formState:{ errors }, setValue, watch, setError } = useForm<IFormData>({
    defaultValues: {
      start: timeshare?.start || '',
      end: timeshare?.end || '',
      view: timeshare?.view || '',
      bedrooms: timeshare?.bedrooms || '',
      bathrooms: timeshare?.bathrooms || '',
      netPrice: timeshare?.netPrice || '',
      ownerNotes: timeshare?.ownerNotes || '',
    }
  });

  const startDate = watch('start');
  const endDate = watch('end');

  const onSubmit = handleSubmit((data:IFormData) => {
    if (!startDate && endDate) {
      setError('start', { type: 'required', message: 'Either both or none dates should be selected' }, { shouldFocus: true });
      return;
    }

    if (startDate && !endDate) {
      setError('end', { type: 'required', message: 'Either both or none dates should be selected' }, { shouldFocus: true });
      return;
    }

    if (!selectedProperty && !timeshareId) {
      setSelectedPropertyError(true);
      return;
    }

    const { start, end, netPrice } = data;
    const nextData:any = {
      ...data,
      start: start ? dayjs(start).format('YYYY-MM-DD') : null,
      end: start ? dayjs(end).format('YYYY-MM-DD') : null,
    }
    if (netPrice) nextData['netPrice'] = +netPrice;

    setIsLoading(true);

    if (timeshareId) {
      const newData = { id: timeshareId, ...nextData };
      dispatch(TimesharesAsync.updateTimeshares(newData))
        .unwrap()
        .then(() => onClose())
        .finally(() => setIsLoading(false));
    } else {
      const newData = { propertyId: selectedProperty?.id, ...nextData };
      dispatch(TimesharesAsync.createTimeshares(newData))
        .unwrap()
        .then(() => {
          dispatch(UiActions.enqueueSnackbar({ message: `${getContent('labels').labelTimeshareSingularText} was created` }));
        })
        .then(() => onClose())
        .finally(() => setIsLoading(false));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(TimesharesActions.setFormData(null));
    }
    // eslint-disable-next-line
  }, []);

  const { Dialog, openDialog, closeDialog } = useDialog();

  // eslint-disable-next-line
  const debounceProperty = useCallback(debounce((search:string) => {
    if (search.trim()) {
      setIsLoadingProperties(true);
      dispatch(PropertiesAsync.fetchProperties({ search, size: 50 }))
        .unwrap()
        .finally(() => setIsLoadingProperties(false));
    } else {
      dispatch(PropertiesActions.clearProperties());
    }
  }, 500), []);

  const onChangeProperty = (event:ChangeEvent<HTMLInputElement>) => {
    debounceProperty(event.target.value);
  }

  return (
    <Fragment>
      <DialogTitle>{`${timeshareId ? 'Edit' : 'Create'} ${getContent('labels').labelTimeshareSingularText}`}</DialogTitle>
      <DialogContent dividers>
        <form onSubmit={onSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              {/* Start date */}
              <Controller
                control={control} name="start"
                rules={{ validate: {
                  isValid: (date: Dayjs | null) => isValidDate(date),
                } }}
                render={({ field: { onChange, value } }) => (
                  <DesktopDatePicker
                    value={value ? dayjs(value) : null}
                    minDate={dayjs().subtract(10, 'year')}
                    maxDate={endDate ? dayjs(endDate).subtract(1, 'day') : dayjs().add(10, 'year')}
                    onChange={(date:any) => {
                      onChange(date);
                      if (date && !endDate) {
                        setValue('end', dayjs(date).add(7, 'day'));
                      }
                    }}
                    label="Start date"
                    views={['year', 'month', 'day']}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        margin: 'normal',
                        error: Boolean(errors.start),
                        helperText: errors.start ? errors.start.message : ''
                      },
                      actionBar: {
                        actions: ['clear', 'accept'],
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              {/* End date */}
              <Controller
                control={control} name="end"
                rules={{ validate: {
                  isValid: (date: Dayjs | null) => isValidDate(date),
                } }}
                render={({ field: { onChange, value } }) => (
                  <DesktopDatePicker
                    value={value ? dayjs(value) : null}
                    maxDate={dayjs().add(10, 'year')}
                    minDate={startDate ? dayjs(startDate).add(1, 'day') : dayjs().subtract(10, 'year')}
                    onChange={(date:any) => {
                      onChange(date);
                      if (date && !startDate) {
                        setValue('start', dayjs(date).subtract(7, 'day'));
                      }
                    }}
                    label="End date"
                    views={['year', 'month', 'day']}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        margin: 'normal',
                        error: Boolean(errors.end),
                        helperText: errors.end ? errors.end.message : ''
                      },
                      actionBar: {
                        actions: ['clear', 'accept'],
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              {/* Amount bedrooms */}
              <Controller
                control={control} name="bedrooms"
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="Bedrooms-select-label">Bedrooms</InputLabel>
                    <Select
                      {...field}
                      labelId="Bedrooms-select-label"
                      id="Bedrooms-select"
                      label="Bedrooms"
                      name="bedrooms"
                    >
                      <MenuItem value="">Unknown</MenuItem>
                      <MenuItem value="studio">Studio</MenuItem>
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              {/* Amount bathrooms */}
              <Controller
                control={control} name="bathrooms"
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="Bathrooms-select-label">Bathrooms</InputLabel>
                    <Select
                      {...field}
                      labelId="Bathrooms-select-label"
                      id="Bathrooms-select"
                      label="Bathrooms"
                      name="bathrooms"
                    >
                      <MenuItem value="">Unknown</MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              {/* View */}
              <Controller
                control={control} name="view"
                rules={{ required: isFieldRequired }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="View"
                    select
                    fullWidth
                    error={Boolean(errors.view)}
                    helperText={errors.view ? errors.view.message : ''}
                    required
                  >
                    {(Object.keys(ViewTypes) as Array<keyof typeof ViewTypes>).map((key) => (
                      <MenuItem key={key} value={ViewTypes[key]}>{ViewTypes[key]}</MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              {/* netPrice */}
              <Controller
                control={control} name="netPrice"
                render={({ field }) => (
                  <Input
                    {...field}
                    fullWidth
                    label="Price"
                    type="number"
                    margin="none"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                  />
                )}
              />
            </Grid>

            {!timeshareId && (
              <Fragment>
                <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center', mt: 2, width: '100%' }}>
                  <Autocomplete
                    sx={{ flexGrow: 1, ml: 2 }}
                    disablePortal
                    id="my-property-timeshare-select-propertyId"
                    options={properties}
                    value={selectedProperty}
                    getOptionLabel={(property) => property.name}
                    onChange={(_:any, value:IProperty | null) => handleSelectedProperty(value || null)}
                    onClose={() => debounceProperty('')}
                    loading={isLoadingProperties}
                    loadingText="Search..."
                    noOptionsText=""
                    forcePopupIcon={false}
                    filterOptions={options => options}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id} style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => 
                      <TextField
                        {...params}
                        label="Property"
                        fullWidth
                        required
                        error={selectedPropertyError}
                        helperText={selectedPropertyError ? 'This field is required' : ''}
                        onChange={onChangeProperty}
                        placeholder="e.g. Marriott"
                      />
                    }
                  />
                  <Button
                    startIcon={<AddIcon />}
                    sx={{ height: '56px', width: 'max-content', display: { xs: 'none', md: 'inline-flex' } }}
                    onClick={openDialog}
                  >
                    Create property
                  </Button>
                  <IconButton
                    sx={{ height: '56px', display: { md: 'none' }, color: getContent('theme').secondaryColor }}
                    onClick={openDialog}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
                <FormHelperText sx={{ ml: 2, mt: 0.5 }}>
                  Start typing to find your property. If not found then create a new property.
                </FormHelperText>
              </Fragment>
            )}

            <Grid item xs={12}>
              {/* ownerNotes */}
              <Controller
                control={control} name="ownerNotes"
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Notes"
                    type="text"
                    multiline
                    rows={3}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ width: '100%' }}>
        <Button
          variant="text"
          color="primary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          loading={isLoading}
          onClick={onSubmit}
          variant="contained"
          color="primary"
        >
          {timeshareId ? 'Save' : 'Create' }
        </LoadingButton>
      </DialogActions>
      <Dialog maxWidth="sm">
        <PropertiesFormDialog onClose={closeDialog} />
      </Dialog>
    </Fragment>
  )
}

export default MyTimesharesForm;
