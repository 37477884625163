import { FC, Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Redux
import { useAppDispatch, useAppSelector } from "hooks/redux";
import RegionsAsync from "store/regions/regionsAsync";
import LandingPagesAsync from "store/landingPages/landingPagesAsync";
import TimesharesAsync from "store/timeshares/timesharesAsync";
import CountriesAsync from "store/countries/countriesAsync";
import { regionsActions } from "store/regions/regionsSlice";
import { landingPagesActions } from "store/landingPages/landingPagesSlice";
import { TimesharesActions } from "store/timeshares/timesharesSlice";
import { selectRegion } from "store/regions/regionsSelectors";
import { selectTimeshares, selectTotal } from "store/timeshares/timesharesSelectors";
import { selectCountry } from "store/countries/countriesSelectors";
import IRegion from "models/Region";
import ICountry from "models/Country";
// MUI
import { makeStyles } from "@mui/styles";
import { Box, Grid, Pagination, Typography } from "@mui/material";
// Components
import ReservationRequestForm from "components/ReservationRequest.form";
import TimeshareCard from "components/TimeshareCard";
import Title from "components/Title";
import { Loader } from "components/Utilities";
import LandingPage from "components/LandingPage";

const limit = 15;

const LocationPage:FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const didMountRef = useRef<any>(null);
  const { countryNameSlug, regionNameSlug } = useParams();

  const timeshares = useAppSelector(selectTimeshares);
  const total = useAppSelector(selectTotal);
  const region = useAppSelector(selectRegion);
  const country = useAppSelector(selectCountry);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ page, setPage ] = useState(1);

  const onChangePage = (value: number) => {
    setPage(value);
  }

  const fetchTimeshares = (params:any) => {
    dispatch(TimesharesAsync.fetchTimeshares({ size: limit, page: page - 1, ...params }))
      .unwrap()
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }
    
    const params:any = {};
    if (region && region.id) params['regionId'] = region.id;
    if (country && country.id) params['countryId'] = country.id;

    fetchTimeshares(params);

    // eslint-disable-next-line
  }, [page]);


  const fetchRegion = () => {
    dispatch(RegionsAsync.fetchRegionBySlug({ countryNameSlug, regionNameSlug })).unwrap()
      .then((region:IRegion) => {
        if (region.id) {
          fetchTimeshares({ regionId: region.id });
          if (region.landingPageId) dispatch(LandingPagesAsync.fetchLandingPage(region.landingPageId));
        } else {
          navigate(`/timeshares/${countryNameSlug}`);
          fetchCountry();
        }
      })
      .catch(() => {
        navigate(`/timeshares/${countryNameSlug}`);
        fetchCountry();
      })
      .finally(() => setIsLoading(false));
  }

  const fetchCountry = () => {
    dispatch(CountriesAsync.fetchCountryBySlug({ nameSlug: countryNameSlug })).unwrap()
      .then((country:ICountry) => {
        if (country.id) {
          fetchTimeshares({ countryId: country.id });
          if (country.landingPageId) dispatch(LandingPagesAsync.fetchLandingPage(country.landingPageId));
        } else {
          navigate(`/timeshares`);
        }
      })
      .catch(() => {
        navigate(`/timeshares`);
      })
      .finally(() => setIsLoading(false));
  }

  const fetchLocation = () => {
    setIsLoading(true);
    if (regionNameSlug) {
      fetchRegion();
    } else {
      fetchCountry();
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    fetchLocation();

    return () => {
      dispatch(TimesharesActions.setParams({}));
      dispatch(landingPagesActions.setInitialField('landingPage'));
      dispatch(regionsActions.setInitialField('region'));
    }
    // eslint-disable-next-line
  }, []);

  const title = useMemo(() => {
    if (country) return `${country ? country.name : ''} available rentals`;
    if (region) return `${region?.name}, ${region?.countryName} available rentals`;
    return 'Available rentals';
  }, [region, country]);

  return (
    <Box sx={{ backgroundColor: '#f5f6f7' }}>
      <div className="big-container">
        <Box sx={{
          padding: '20px 16px',
          '@media (min-width: 600px)': {
            padding: '40px 32px 56px'
          },
          '@media (min-width: 1240px)': {
            padding: '40px 0 56px'
          }
        }}>
          {isLoading ? (
            <Loader />
          ) : (
            <Fragment>
              <LandingPage />
              <Title>{title}</Title>
              <Typography className={classes.subtitle}>{`${total} rentals found`}</Typography>
              <Box sx={{ pt: 2, pb: 2 }}>
                {timeshares ? (
                  <Grid container spacing={2}>
                    {timeshares.map(timeshare => (
                      <Grid item key={timeshare.id} sx={{ maxWidth: '100%', flexBasis: '100%', '@media (min-width: 600px)': { flexBasis: '50%', maxWidth: '50%' }, '@media (min-width: 1900px)': { flexBasis: '33.333%', maxWidth: '33.333%' } }}>
                        <TimeshareCard timeshare={timeshare} />
                      </Grid>
                    ))}
                    {total > limit && (
                      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: 3, alignItems: 'center', pt: 2 }}>
                        <Typography className={classes.subtitle}>
                          {`Showing ${(page - 1) * limit + 1}–${page*limit <= total ? page*limit : total } of ${total}`}
                        </Typography>
                        <Pagination
                          count={Math.ceil(total / limit)}
                          onChange={(_:any, page: number) => onChangePage(page)}
                          page={page}
                          color="primary"
                        />
                      </Box>
                    )}
                  </Grid>
                ) : null}
              </Box>
              <ReservationRequestForm />
            </Fragment>
          )}
        </Box>
      </div>
    </Box>
  );
}
 
export default LocationPage;

const useStyles = makeStyles({
  subtitle: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '48px',
    letterSpacing: '-0.4px',
    color: 'rgba(0, 0, 0, 0.6)',
  }
});
