
import React from 'react';
// components
import Title from 'components/Title';
// mui
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
  title: string;
  items: any;
}

const ListYourPropertyBenefits:React.FC<Props> = ({ title, items }) => {
  const classes = useStyles();

  return (
    <Box className={classes.block}>
      <div className="container">
        <Box sx={{ textAlign: 'center' }}>
          <Title>{title}</Title>
        </Box>
        <Box sx={{ pt: { xs: 2, sm: 3, lg: 4 }, display: 'flex', flexWrap: 'wrap', gap: { xs: 1, sm: 2, lg: 3 } }}>
          {items.map((item: any) => (
            <Box key={item.key} className={classes.card}>
              <img src={item.icon} alt={item.title} className={classes.icon} />
              <Box>
                <Typography className={classes.title}>{item.title}</Typography>
                <Typography className={classes.text}>
                  {item.text}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </div>
    </Box>
  )
}

export default ListYourPropertyBenefits;

const useStyles = makeStyles({
  block: {
    backgroundColor: '#ebeef0',
    padding: '16px',
    '@media (min-width: 600px)': {
      padding: '32px',
    },
    '@media (min-width: 1240px)': {
      padding: '64px 0',
    },
  },
  card: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '8px',
    width: '100%',
    padding: '16px',
    background: '#fff',
    boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.08)',
    borderRadius: '8px',
    '@media (min-width: 600px)': {
      padding: '26px 24px',
      gap: '24px',
      flexDirection: 'row',
      alignItems: 'center',
    },
    '@media (min-width: 1240px)': {
      padding: '38px 40px',
      width: '508px',
    },
  },
  title: {
    paddingBottom: '8px',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '-0.35px',
    '@media (min-width: 600px)': {
      fontSize: '20px',
      lineHeight: '160%',
    },
    '@media (min-width: 1240px)': {
      fontSize: '24px',
      lineHeight: '133.4%',
      letterSpacing: '-0.5px',
    },
  },
  text: {
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '-0.43px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  icon: {
    width: '64px',
    height: '64px',
    '@media (min-width: 600px)': {
      width: '96px',
      height: '96px',
    },
    '@media (min-width: 1240px)': {
      width: '112px',
      height: '112px',
    },
  },
});
