import React from 'react';
// mui
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
// components
import Title from 'components/Title';
// Utilities
import { getContent } from 'utilities/Utilities';

const AboutUs:React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.block}>
      <div className="container">
        <Box textAlign="center"><Title>{getContent('homePage').aboutUs.title}</Title></Box>
        <Box className={classes.cardList}>
          {getContent('homePage').aboutUs.items.map((item: any) => (
            <Box key={item.key} className={classes.card}>
              <img src={item.icon} alt={item.title} className={classes.icon} />
              <Box sx={{ alignSelf: 'flex-start' }}>
                <Typography className={classes.title}>{item.title}</Typography>
                <Typography className={classes.text}>
                  {item.text}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </div>
    </Box>
  )
}

export default AboutUs;

const useStyles = makeStyles({
  block: {
    padding: '16px',
    backgroundColor: '#f5f6f7',
    '@media (min-width: 600px)': {
      padding: '32px',
    },
    '@media (min-width: 1240px)': {
      padding: '40px 0',
    },
  },
  cardList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '8px',
    paddingTop: '16px',
    '@media (min-width: 600px)': {
      paddingTop: '24px',
      gap: '16px',
    },
    '@media (min-width: 1240px)': {
      paddingTop: '32px',
      gap: '24px',
    },
  },
  card: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '24px',
    width: '100%',
    padding: '16px',
    background: '#fff',
    boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.08)',
    borderRadius: '8px',
    '@media (min-width: 600px)': {
      alignItems: 'center',
      flexDirection: 'row',
      padding: '24px 28px',
    },
    '@media (min-width: 1240px)': {
      width: '508px',
      padding: '24px 40px',
    },
  },
  icon: {
    width: '64px',
    height: '64px',
    '@media (min-width: 600px)': {
      width: '96px',
      height: '96px',
    },
    '@media (min-width: 1240px)': {
      width: '112px',
      height: '112px',
    },
  },
  title: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '133.4%',
    letterSpacing: '-0.5px',
    paddingBottom: '8px',
    '@media (min-width: 600px)': {
      fontSize: '20px',
    },
    '@media (min-width: 1240px)': {
      fontSize: '24px',
    },
  },
  text: {
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '-0.43px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
})