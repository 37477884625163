import { FC, Fragment, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
// Hooks
import useMenu from 'hooks/useMenu';
import useDialog from 'hooks/useDialog';
// Models
import IAsset from 'models/Asset';
// Actions
import { AssetsActions } from 'store/assets/assetsSlice';
import { UsersActions } from 'store/users/usersSlice';
// Selectors
import { selectFile } from 'store/assets/assetsSelectors';
import { selectCurrentUser, selectRemoveImage } from 'store/users/usersSelectors';
// MUI
import {
  Avatar as MuiAvatar,
  Box, Button, DialogActions, DialogContent, useMediaQuery
} from '@mui/material';
//
import Avatar from 'react-avatar-edit';

type Props = {
  image?: IAsset;
}

const ImageUploader:FC<Props> = ({ image }) => {
  const dispatch = useAppDispatch();

  const avatarRef = useRef<any>(null);

  const isTablet = useMediaQuery('(min-width:600px)');

  const currentUser = useAppSelector(selectCurrentUser);
  const file = useAppSelector(selectFile);
  const removeImage = useAppSelector(selectRemoveImage);

  const imgUrl = file ? URL.createObjectURL(file) : image ? image.url : '';
  const [imgCrop, setImgCrop] = useState<string>('');

  const { Menu, MenuItem, openMenu } = useMenu();
  const { Dialog, openDialog, closeDialog } = useDialog();

  const handleCloseDialog = () => {
    closeDialog();
    setImgCrop('');
  }

  const onCrop = (fileBase64: string) => {
    setImgCrop(fileBase64);
  }

  const save = async () => {
    const response = await fetch(imgCrop);
    const blob = await response.blob();
    dispatch(AssetsActions.setFile(blob as File));
    closeDialog();
  }

  const handleDelete = () => {
    if (file) {
      dispatch(AssetsActions.setInitialField('file'));
      if (avatarRef.current?.value) avatarRef.current.value = '';
    }
    if (!file && image) {
      dispatch(UsersActions.setRemoveImage(true));
    }
  }

  const onClose = () => setImgCrop('');

  useEffect(() => {
    return () => {
      dispatch(AssetsActions.setInitialField('file'));
      dispatch(UsersActions.setRemoveImage(false));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Dialog>
        <DialogContent dividers sx={{ display: 'flex', justifyContent: 'center' }}>
          <Avatar
            ref={avatarRef}
            width={isTablet ? 360 : 260}
            height={isTablet ? 300 : 200}
            onCrop={onCrop}
            onClose={onClose}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={save}
            disabled={!imgCrop}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>

      <Box onClick={openMenu} sx={{
        border: '2px solid #eee',
        borderRadius: '50%',
        overflow: 'hidden',
        width: { xs: '75px', md: '120px' },
        height: { xs: '75px', md: '120px' },
        marginLeft: 'auto',
        marginRight: 'auto',
        mb: { xs: 2.5, md: 5 },
        cursor: 'pointer'
      }}>
        {imgUrl ? (
          <img
            src={imgUrl} alt=""
            style={{
              width: '100%', height: '100%',
              objectFit: 'cover', objectPosition: 'center',
              opacity: !file && removeImage ? 0.5 : 1,
            }}
          />
        ) : (
          <MuiAvatar sx={{ width: '100%', height: '100%', fontSize: { xs: '32px', md: '50px' } }}>
            {currentUser?.firstName[0]}
          </MuiAvatar>
        )}
      </Box>

      <Menu>
        <MenuItem onClick={openDialog}>
          Upload photo
        </MenuItem>
        {(!!file || !!image) && (
          <MenuItem onClick={handleDelete} disabled={!file && removeImage}>
            Delete photo
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  );
}

export default ImageUploader;
