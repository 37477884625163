import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IState {
  file: File | null;
}

const initialState:IState = {
  file: null,
};

const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    setFile: (state, action:PayloadAction<File>) => {
      state.file = action.payload;
    },
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
  }
});

export const AssetsActions = assetsSlice.actions;

export default assetsSlice.reducer;
