import { configureStore, combineReducers } from "@reduxjs/toolkit";
// Middlewares
import errorMiddleware from "middlewares/errorMiddleware";
import messageMiddleware from "middlewares/messageMiddleware";
// Reducers
import UiReducer from './ui/uiSlice';
import UsersReducer from './users/usersSlice';
import AuthReducer from './auth/authSlice';
import TimesharesReducer from './timeshares/timesharesSlice';
import ReservationsReducer from './reservations/reservationsSlice';
import ReservationRequestsReducer from './reservationRequests/reservationRequestsSlice';
import PropertiesReducer from './properties/propertiesSlice';
import DestinationTypesReducer from './destinationTypes/destinationTypesSlice';
import MessagesReducer from './messages/messagesSlice';
import NotificationsReducer from './notifications/notificationsSlice';
import AccountsReducer from './accounts/accountsSlice';
import PaymentsReducer from './payments/paymentsSlice';
import ChatsReducer from './chats/chatsSlice';
import SubscriptionsReducer from './subscriptions/subscriptionsSlice';
import PollsReducer from './polls/pollsSlice';
import AssetsReducer from './assets/assetsSlice';
import CalendarsReducer from './calendars/calendarsSlice';
import CountriesReducer from './countries/countriesSlice';
import RegionsReducer from './regions/regionsSlice';
import LandingPagesReducer from './landingPages/landingPagesSlice';

const rootReducer = combineReducers({
  ui: UiReducer,
  users: UsersReducer,
  auth: AuthReducer,
  timeshares: TimesharesReducer,
  reservations: ReservationsReducer,
  reservationRequests: ReservationRequestsReducer,
  properties: PropertiesReducer,
  destinationTypes: DestinationTypesReducer,
  messages: MessagesReducer,
  notifications: NotificationsReducer,
  accounts: AccountsReducer,
  payments: PaymentsReducer,
  chats: ChatsReducer,
  subscriptions: SubscriptionsReducer,
  polls: PollsReducer,
  assets: AssetsReducer,
  calendars: CalendarsReducer,
  countries: CountriesReducer,
  regions: RegionsReducer,
  landingPages: LandingPagesReducer
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => 
      getDefaultMiddleware({
        serializableCheck: false
      }).concat(errorMiddleware).concat(messageMiddleware)
  });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
